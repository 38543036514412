"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var use_search_1 = require("@/composables/use-search");
var search_query_1 = require("@/queries/search.query");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
var routeName_type_1 = require("@/types/routeName.type");
var use_polly_1 = require("@/composables/use-polly");
var publications_query_1 = require("@/queries/publications.query");
var router_1 = require("@/router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BackButton',
    setup: function (__props) {
        var isSearchActive = (0, vue_3.computed)(function () { return search_query_1.resultResponse.value !== undefined; });
        var route = (0, vue_router_1.useRoute)();
        var isButtonInvisible = (0, vue_3.computed)(function () {
            return (route.name === routeName_type_1.SELECTION_PAGE_NAME && !isSearchActive.value) ||
                route.name === routeName_type_1.THANK_YOU_PAGE_NAME ||
                (!publications_query_1.publicationData.value && (0, use_pageNavigation_1.getCurrentInputStepIndex)() === 0);
        });
        var goToPreviousStep = function () {
            var currentFormStepIndex = (0, use_pageNavigation_1.getCurrentInputStepIndex)();
            if (currentFormStepIndex === 0)
                goExitFormFlow();
            else if (currentFormStepIndex > 0)
                goBackInFormFlow(currentFormStepIndex);
            else
                goBackToHardcodedPage();
        };
        function goExitFormFlow() {
            router_1.default.push({
                name: routeName_type_1.HELP_PAGE_NAME,
                params: use_polly_1.currentCollection.value ? { collectionId: use_polly_1.currentCollection.value.id } : undefined,
                query: { backNavigationNavigation: "true" },
            });
        }
        function goBackInFormFlow(currentFormStep) {
            router_1.default.push({ name: use_polly_1.currentFormFlow.value[currentFormStep - 1] });
        }
        function goBackToHardcodedPage() {
            var currentRouteName = router_1.default.currentRoute.value.name;
            switch (currentRouteName) {
                case routeName_type_1.HELP_PAGE_NAME:
                    router_1.default.push({ name: routeName_type_1.SELECTION_PAGE_NAME, query: use_search_1.searchInput.value ? { search: use_search_1.searchInput.value } : {} });
                    break;
                case routeName_type_1.LOGIN_INFO_PAGE_NAME:
                    router_1.default.push({
                        name: routeName_type_1.HELP_PAGE_NAME,
                        params: use_polly_1.currentCollection.value ? { collectionId: use_polly_1.currentCollection.value.id } : undefined,
                        query: { backNavigationNavigation: "true" },
                    });
                    break;
                case routeName_type_1.SELECTION_PAGE_NAME:
                    router_1.default.push({ name: routeName_type_1.SELECTION_PAGE_NAME });
                    break;
                default:
                    console.error("Go back one step. Because of unexpected browser back/forward behavior this should never take effect");
                    router_1.default.go(-1);
            }
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                type: "button",
                class: (0, vue_2.normalizeClass)(["mt-4 flex w-full text-xs font-medium", { invisible: isButtonInvisible.value }]),
                onClick: _cache[0] || (_cache[0] = function ($event) { return (goToPreviousStep()); })
            }, [
                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                    class: "mr-1 h-4 w-4",
                    "icon-name": "arrow-thin-left"
                }),
                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(_ctx.$t("back")), 1)
            ], 2));
        };
    }
});
