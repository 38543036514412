"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "my-4 text-xl font-semibold" };
var _hoisted_2 = { class: "mb-20" };
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var use_redirect_1 = require("@/composables/use-redirect");
var router_1 = require("@/router");
var SharedButton_vue_1 = require("@SharedVueComponents/SharedButton.vue");
var vue_3 = require("vue");
var use_restrictions_1 = require("@/composables/use-restrictions");
exports.default = (0, vue_1.defineComponent)({
    __name: 'LoginInfoPage',
    setup: function (__props) {
        var _this = this;
        (0, vue_3.onBeforeMount)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, use_redirect_1.redirectLocalStorage)()];
                    case 1:
                        _a.sent();
                        redirectToFormIfPermissionsAreValid();
                        return [2 /*return*/];
                }
            });
        }); });
        function redirectToFormIfPermissionsAreValid() {
            return __awaiter(this, void 0, Promise, function () {
                var collectionIdParams, isCollectionObject, collectionIdParam;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            collectionIdParams = router_1.default.currentRoute.value.params.collectionId;
                            isCollectionObject = typeof collectionIdParams === "object";
                            collectionIdParam = isCollectionObject ? collectionIdParams[0] : collectionIdParams;
                            return [4 /*yield*/, (0, use_restrictions_1.hasUserPermission)()];
                        case 1:
                            if (_a.sent())
                                (0, use_pageNavigation_1.startContactForm)(collectionIdParam, true);
                            return [2 /*return*/];
                    }
                });
            });
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createElementVNode)("h2", _hoisted_1, (0, vue_2.toDisplayString)(_ctx.$t("LoginInfoPage.headline")), 1),
                (0, vue_2.createElementVNode)("p", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.$t("LoginInfoPage.helpText")), 1),
                (0, vue_2.createVNode)(SharedButton_vue_1.default, {
                    primary: "",
                    class: "mb-4 w-3/4 lg-down:w-full",
                    onClick: (0, vue_2.unref)(use_redirect_1.triggerReauthOrLogin)
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("LoginInfoPage.cta")), 1)
                    ]; }),
                    _: 1
                }, 8, ["onClick"])
            ]));
        };
    }
});
