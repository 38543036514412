"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mb-8" };
var _hoisted_2 = { class: "mt-4" };
var _hoisted_3 = ["disabled"];
var _hoisted_4 = {
    class: "sr-only",
    for: "searchInput"
};
var _hoisted_5 = ["placeholder", "disabled"];
var _hoisted_6 = ["disabled"];
var _hoisted_7 = {
    key: 0,
    class: "mt-1 text-sm text-error"
};
var _hoisted_8 = {
    "aria-live": "polite",
    class: "mt-4"
};
var _hoisted_9 = {
    key: 0,
    class: "mt-6"
};
var _hoisted_10 = { class: "truncate font-normal" };
var _hoisted_11 = {
    key: 1,
    class: "mb-10 mt-6"
};
var _hoisted_12 = { class: "mb-8 flex justify-center" };
var _hoisted_13 = { class: "mb-2 font-bold" };
var search_query_1 = require("@/queries/search.query");
var use_search_1 = require("@/composables/use-search");
var search_query_2 = require("@/queries/search.query");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var use_trackingInteractions_1 = require("@/composables/use-trackingInteractions");
var vue_i18n_1 = require("vue-i18n");
var vue_3 = require("vue");
var routeName_type_1 = require("@/types/routeName.type");
var router_1 = require("@/router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FaqSearch',
    props: {
        searchParam: {}
    },
    setup: function (__props) {
        var t = (0, vue_i18n_1.useI18n)().t;
        var props = __props;
        var errorMessage = (0, vue_3.computed)(function () {
            if (search_query_1.error.value === "missing_characters_error") {
                return t("search.missingCharactersError");
            }
            return t("search.genericSearchError");
        });
        (0, vue_3.watch)(function () { return props.searchParam; }, function () {
            updateSearchToSearchParam();
        });
        (0, vue_3.onMounted)(function () {
            updateSearchToSearchParam();
        });
        function handleDeleteButtonClicked() {
            (0, use_search_1.clearSearch)();
            router_1.default.push({ name: routeName_type_1.SELECTION_PAGE_NAME });
        }
        function updateSearchToSearchParam() {
            var searchParam = props.searchParam;
            if (use_search_1.searchInput.value === searchParam)
                return; // e.g. browser back after search result click
            else if (searchParam) {
                use_search_1.searchInput.value = searchParam;
                (0, search_query_1.getSearchRequest)(searchParam);
            }
            else {
                (0, use_search_1.clearSearch)();
            }
        }
        return function (_ctx, _cache) {
            var _a, _b;
            var _component_routerLink = (0, vue_2.resolveComponent)("routerLink");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("form", {
                        class: "flex h-10 cursor-text items-center rounded-full bg-gray-20 px-1 text-gray-80 focus-within:bg-gray-40 focus-within:text-black hover:bg-gray-40 px360:px-5",
                        onSubmit: _cache[3] || (_cache[3] = (0, vue_2.withModifiers)(function ($event) { return ((0, vue_2.unref)(search_query_1.getSearchRequest)((0, vue_2.unref)(use_search_1.searchInput))); }, ["prevent"]))
                    }, [
                        (0, vue_2.createElementVNode)("button", {
                            type: "button",
                            class: "mr-1 grid h-10 w-10 shrink-0 place-content-center",
                            disabled: (0, vue_2.unref)(search_query_1.fetching),
                            "data-test-name": "data-test-help-search-magnifier",
                            onClick: _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.unref)(search_query_1.getSearchRequest)((0, vue_2.unref)(use_search_1.searchInput))); })
                        }, [
                            (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                class: (0, vue_2.normalizeClass)([{ 'text-error': (0, vue_2.unref)(search_query_1.error) }, "h-5 w-5"]),
                                "icon-name": "magnifying-glass",
                                title: "Suche abschicken"
                            }, null, 8, ["class"])
                        ], 8, _hoisted_3),
                        (0, vue_2.createElementVNode)("label", _hoisted_4, (0, vue_2.toDisplayString)(_ctx.$t("search.searchHelpArea")), 1),
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("input", {
                            id: "searchInput",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.isRef)(use_search_1.searchInput) ? (use_search_1.searchInput).value = $event : null); }),
                            class: "h-11 w-1/2 flex-1 appearance-none border-0 bg-transparent text-base leading-5 placeholder:text-gray-80 px360:w-auto",
                            type: "search",
                            placeholder: _ctx.$t('search.searchHelpArea'),
                            "data-test-name": "data-test-help-search-input",
                            disabled: (0, vue_2.unref)(search_query_1.fetching),
                            enterkeyhint: "search"
                        }, null, 8, _hoisted_5), [
                            [
                                vue_2.vModelText,
                                (0, vue_2.unref)(use_search_1.searchInput),
                                void 0,
                                { trim: true }
                            ]
                        ]),
                        ((0, vue_2.unref)(use_search_1.searchInput) || (0, vue_2.unref)(search_query_2.resultResponse))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                                key: 0,
                                type: "button",
                                class: "ml-2 grid h-10 w-10 shrink-0 place-content-center",
                                disabled: (0, vue_2.unref)(search_query_1.fetching),
                                "data-test-name": "data-test-help-search-button",
                                onClick: _cache[2] || (_cache[2] = function ($event) { return (handleDeleteButtonClicked()); })
                            }, [
                                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                    class: (0, vue_2.normalizeClass)([{ 'text-error': (0, vue_2.unref)(search_query_1.error) }, "h-4 w-4"]),
                                    "icon-name": "close",
                                    title: "Text löschen"
                                }, null, 8, ["class"])
                            ], 8, _hoisted_6))
                            : (0, vue_2.createCommentVNode)("", true)
                    ], 32),
                    ((0, vue_2.unref)(search_query_1.error))
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("p", _hoisted_7, (0, vue_2.toDisplayString)(errorMessage.value), 1))
                        : (0, vue_2.createCommentVNode)("", true)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_8, [
                    (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.$t("search.searchResults", ((_a = (0, vue_2.unref)(search_query_2.resultResponse)) === null || _a === void 0 ? void 0 : _a.length) || 0)) + " ’" + (0, vue_2.toDisplayString)((0, vue_2.unref)(search_query_1.lastSearchTerm)) + "’ ", 513), [
                        [vue_2.vShow, (0, vue_2.unref)(search_query_2.resultResponse)]
                    ])
                ]),
                ((_b = (0, vue_2.unref)(search_query_2.resultResponse)) === null || _b === void 0 ? void 0 : _b.length)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("ol", _hoisted_9, [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(search_query_2.resultResponse), function (result) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                                key: result.id,
                                class: "focus-within:bg-gray-40 hover:bg-gray-40 active:bg-gray-40"
                            }, [
                                _cache[4] || (_cache[4] = (0, vue_2.createElementVNode)("hr", { class: "border-t border-gray-30 font-semibold" }, null, -1)),
                                (0, vue_2.createVNode)(_component_routerLink, {
                                    to: { name: (0, vue_2.unref)(routeName_type_1.HELP_PAGE_NAME), params: { collectionId: result.collections[0].id }, query: { faq: result.id } },
                                    class: "block p-4",
                                    onClick: function ($event) { return ((0, vue_2.unref)(use_trackingInteractions_1.trackSearchResultClicked)((0, vue_2.unref)(use_search_1.searchInput), result.name)); }
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(result.name) + " ", 1),
                                        (0, vue_2.createElementVNode)("div", _hoisted_10, "A: " + (0, vue_2.toDisplayString)(result.contentsnippet), 1)
                                    ]; }),
                                    _: 2
                                }, 1032, ["to", "onClick"])
                            ]));
                        }), 128))
                    ]))
                    : ((0, vue_2.unref)(search_query_2.resultResponse) !== undefined)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_11, [
                            (0, vue_2.createElementVNode)("div", _hoisted_12, [
                                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                    "icon-name": "magnifier-error",
                                    class: "h-15 w-15"
                                })
                            ]),
                            (0, vue_2.createElementVNode)("p", _hoisted_13, (0, vue_2.toDisplayString)(_ctx.$t("search.noResults")), 1),
                            (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t("search.noResultsHint")), 1)
                        ]))
                        : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
