"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mt-8" };
var _hoisted_2 = { class: "mb-10 text-gray-70" };
var _hoisted_3 = {
    key: 0,
    class: "text-gray-70"
};
var vue_3 = require("vue");
var vue_recaptcha_1 = require("vue-recaptcha");
var ocm_object_1 = require("@ocm/services/ocm-object");
var LoadingSpinner_vue_1 = require("@/components/LoadingSpinner.vue");
var HeadlineText_vue_1 = require("@/components/HeadlineText.vue");
var BtnLink_vue_1 = require("@/components/BtnLink.vue");
var use_mail_1 = require("@/composables/use-mail");
var use_messageData_1 = require("@/composables/use-messageData");
var use_trackingErrors_1 = require("@/composables/use-trackingErrors");
var use_autofill_1 = require("@/composables/use-autofill");
var vue_i18n_1 = require("vue-i18n");
var use_formValidity_1 = require("@/composables/use-formValidity");
var captcha_config_1 = require("@/queries/captcha.config");
var TransitionSection_vue_1 = require("@/components/TransitionSection.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PersonalDataPage',
    setup: function (__props) {
        var _this = this;
        var t = (0, vue_i18n_1.useI18n)({}).t;
        var formRef = (0, vue_3.ref)();
        var captchaEl = (0, vue_3.ref)();
        var loading = (0, vue_3.ref)(false);
        var hideLoadingSpinner = function () {
            loading.value = false;
        };
        var showLoadingSpinner = function () {
            loading.value = true;
        };
        var captchaIsVerified = (0, vue_3.ref)(false);
        var captchaToken = (0, vue_3.ref)();
        var submit = function () { return __awaiter(_this, void 0, Promise, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!((_a = captcha_config_1.captchaData.value) === null || _a === void 0 ? void 0 : _a.captchaActive)) return [3 /*break*/, 2];
                        return [4 /*yield*/, ((_b = captchaEl === null || captchaEl === void 0 ? void 0 : captchaEl.value) === null || _b === void 0 ? void 0 : _b.execute())];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        if (!(!((_c = captcha_config_1.captchaData.value) === null || _c === void 0 ? void 0 : _c.captchaActive) || captchaIsVerified.value)) return [3 /*break*/, 4];
                        showLoadingSpinner();
                        return [4 /*yield*/, (0, use_mail_1.sendMail)(captchaToken.value)];
                    case 3:
                        _d.sent();
                        hideLoadingSpinner();
                        _d.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        (0, vue_3.onBeforeMount)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, captcha_config_1.getCaptchaData)()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, (0, use_autofill_1.getAndAutofillContactData)(t)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        (0, vue_3.onMounted)(function () {
            (0, use_formValidity_1.useRerouteToValidStep)(formRef);
        });
        var verifyCaptcha = function (token) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        showLoadingSpinner();
                        return [4 /*yield*/, (0, use_mail_1.sendMail)(token)];
                    case 1:
                        _a.sent();
                        captchaIsVerified.value = true;
                        captchaToken.value = token;
                        hideLoadingSpinner();
                        return [2 /*return*/];
                }
            });
        }); };
        var expiredCaptcha = function () {
            use_mail_1.mailErrorText.value = "generalError";
            console.log("expired");
        };
        var errorCaptcha = function () {
            use_mail_1.mailErrorText.value = "generalError";
            console.log("error captcha");
        };
        return function (_ctx, _cache) {
            var _a;
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            var _component_i18n_t = (0, vue_2.resolveComponent)("i18n-t");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(TransitionSection_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (loading.value)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(LoadingSpinner_vue_1.default, { key: 0 }))
                            : (0, vue_2.createCommentVNode)("", true)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(HeadlineText_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("personalDataPage.headline")), 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(_component_FormKit, {
                    ref_key: "formRef",
                    ref: formRef,
                    type: "form",
                    "submit-label": _ctx.$t('personalDataPage.sendRequest'),
                    config: { validationVisibility: 'submit' },
                    errors: [_ctx.$t((0, vue_2.unref)(use_mail_1.mailErrorText))],
                    onSubmit: submit
                }, {
                    default: (0, vue_2.withCtx)(function () {
                        var _a;
                        return [
                            (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                (0, vue_2.createVNode)(_component_FormKit, {
                                    modelValue: (0, vue_2.unref)(use_messageData_1.salutation),
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.salutation) ? (use_messageData_1.salutation).value = $event : null); }),
                                    type: "ocmChipRadio",
                                    name: _ctx.$t('personalDataPage.salutation'),
                                    validation: "required",
                                    plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                                    options: [_ctx.$t('personalDataPage.mr'), _ctx.$t('personalDataPage.mrs')]
                                }, null, 8, ["modelValue", "name", "plugins", "options"])
                            ]),
                            (0, vue_2.createVNode)(_component_FormKit, {
                                modelValue: (0, vue_2.unref)(use_messageData_1.firstName),
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.firstName) ? (use_messageData_1.firstName).value = $event : null); }),
                                type: "ocmTextInput",
                                "input-type": "text",
                                label: _ctx.$t('personalDataPage.firstName'),
                                name: _ctx.$t('personalDataPage.firstName'),
                                validation: "required",
                                plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin]
                            }, null, 8, ["modelValue", "label", "name", "plugins"]),
                            (0, vue_2.createVNode)(_component_FormKit, {
                                modelValue: (0, vue_2.unref)(use_messageData_1.lastName),
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.lastName) ? (use_messageData_1.lastName).value = $event : null); }),
                                type: "ocmTextInput",
                                "input-type": "text",
                                label: _ctx.$t('personalDataPage.lastName'),
                                name: _ctx.$t('personalDataPage.lastName'),
                                validation: "required",
                                plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin]
                            }, null, 8, ["modelValue", "label", "name", "plugins"]),
                            (0, vue_2.createVNode)(_component_FormKit, {
                                modelValue: (0, vue_2.unref)(use_messageData_1.email),
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.email) ? (use_messageData_1.email).value = $event : null); }),
                                type: "ocmTextInput",
                                "input-type": "email",
                                label: _ctx.$t('personalDataPage.email'),
                                name: _ctx.$t('personalDataPage.email'),
                                validation: "required|email",
                                plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                                disabled: (0, vue_2.unref)(use_messageData_1.emailDisabled)
                            }, null, 8, ["modelValue", "label", "name", "plugins", "disabled"]),
                            (0, vue_2.createVNode)(_component_FormKit, {
                                modelValue: (0, vue_2.unref)(use_messageData_1.phone),
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.phone) ? (use_messageData_1.phone).value = $event : null); }),
                                type: "ocmTextInput",
                                "input-type": "tel",
                                label: _ctx.$t('personalDataPage.phone'),
                                name: _ctx.$t('personalDataPage.phone'),
                                plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin]
                            }, null, 8, ["modelValue", "label", "name", "plugins"]),
                            ((_a = (0, vue_2.unref)(captcha_config_1.captchaData)) === null || _a === void 0 ? void 0 : _a.captchaActive)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_recaptcha_1.VueRecaptcha), {
                                    key: 0,
                                    ref_key: "captchaEl",
                                    ref: captchaEl,
                                    sitekey: (0, vue_2.unref)(captcha_config_1.captchaData).publicKey,
                                    "load-recaptcha-script": false,
                                    size: "invisible",
                                    onVerify: verifyCaptcha,
                                    onExpired: expiredCaptcha,
                                    onError: errorCaptcha
                                }, null, 8, ["sitekey"]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.createElementVNode)("p", _hoisted_2, [
                                (0, vue_2.createVNode)(_component_i18n_t, {
                                    tag: "p",
                                    class: "mt-4",
                                    keypath: "personalDataPage.dataText"
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(BtnLink_vue_1.default, {
                                            class: "text-gray-70",
                                            target: "_blank",
                                            href: "".concat((0, vue_2.unref)(ocm_object_1.contextPath), "/").concat(_ctx.$t('personalDataPage.dataLink'), "/")
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("personalDataPage.dataTextPolicy")), 1)
                                            ]; }),
                                            _: 1
                                        }, 8, ["href"]),
                                        _cache[5] || (_cache[5] = (0, vue_2.createTextVNode)(". "))
                                    ]; }),
                                    _: 1
                                })
                            ])
                        ];
                    }),
                    _: 1
                }, 8, ["submit-label", "errors"]),
                ((_a = (0, vue_2.unref)(captcha_config_1.captchaData)) === null || _a === void 0 ? void 0 : _a.captchaActive)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t("personalDataPage.captchaText")), 1))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
