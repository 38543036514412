"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mb-6 mt-10 flex justify-center py-3" };
var _hoisted_2 = { class: "my-4 mb-2 text-xl font-semibold" };
var _hoisted_3 = { class: "mb-20" };
var ocm_object_1 = require("@ocm/services/ocm-object");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var SharedButton_vue_1 = require("@SharedVueComponents/SharedButton.vue");
var routeName_type_1 = require("@/types/routeName.type");
var use_messageData_1 = require("@/composables/use-messageData");
var vue_3 = require("vue");
var use_formValidity_1 = require("@/composables/use-formValidity");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var use_polly_1 = require("@/composables/use-polly");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ThankYouPage',
    setup: function (__props) {
        (0, vue_3.onMounted)(function () {
            var _a;
            var areNotAllStepsCompleted = !use_formValidity_1.areAllStepsCompleted.value; // get state before resetting the form
            resetContactForm();
            if (areNotAllStepsCompleted)
                (0, use_pageNavigation_1.startContactForm)((_a = use_polly_1.currentCollection.value) === null || _a === void 0 ? void 0 : _a.id, true);
        });
        function resetContactForm() {
            use_formValidity_1.completedSteps.value.clear();
            (0, use_messageData_1.resetInputData)();
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                    (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                        "icon-name": "send-email",
                        class: "h-[60px] w-[60px]"
                    })
                ]),
                (0, vue_2.createElementVNode)("h2", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.$t("thankYouPage.headline")), 1),
                (0, vue_2.createElementVNode)("p", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t("thankYouPage.text")), 1),
                (0, vue_2.createVNode)(SharedButton_vue_1.default, {
                    href: "".concat((0, vue_2.unref)(ocm_object_1.contextPath), "/"),
                    variant: "primary",
                    class: "mb-4 w-3/4 lg-down:w-full"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("thankYouPage.cta")), 1)
                    ]; }),
                    _: 1
                }, 8, ["href"]),
                (0, vue_2.createVNode)(SharedButton_vue_1.default, {
                    to: { name: (0, vue_2.unref)(routeName_type_1.SELECTION_PAGE_NAME) },
                    variant: "secondary",
                    class: "w-3/4 lg-down:w-full"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("thankYouPage.ctaSecondary")), 1)
                    ]; }),
                    _: 1
                }, 8, ["to"])
            ]));
        };
    }
});
