"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "tw" };
var _hoisted_2 = ["for"];
var _hoisted_3 = { class: "peer relative hidden text-base leading-5 em64:absolute em64:top-0 em64:block em64:w-full" };
var _hoisted_4 = ["disabled", "tabindex", "aria-hidden"];
var _hoisted_5 = ["data-key", "onClick"];
var vue_3 = require("vue");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var core_1 = require("@vueuse/core");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BaseDropdown',
    props: {
        disabled: { type: Boolean },
        icons: {},
        label: {},
        modelValue: {},
        name: {},
        options: {},
        placeholder: {},
        plugins: {},
        validation: {},
        validationVisibility: {},
        validationMessages: {},
        value: {}
    },
    emits: ["update:modelValue"],
    setup: function (__props, _a) {
        var _this = this;
        var __emit = _a.emit;
        var props = __props;
        var emit = __emit;
        var isEm64 = (0, core_1.useBreakpoints)({ em64: "64em" }).greaterOrEqual("em64");
        var isOpen = (0, vue_3.ref)(false);
        var selectedOption = props.value
            ? (0, vue_3.ref)(props.value)
            : (0, vue_3.computed)({
                get: function () {
                    return props.modelValue || "";
                },
                set: function (val) {
                    emit("update:modelValue", val);
                },
            });
        var selectOption = function (key) {
            selectedOption.value = key.toString();
            isOpen.value = false;
        };
        var selectedIndex = (0, vue_3.computed)(function () { return Object.keys(props.options).findIndex(function (key) { return key === selectedOption.value; }); });
        var dropdownRef = (0, vue_3.ref)(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var formkitSelectRef = (0, vue_3.ref)(null);
        var optionsListRef = (0, vue_3.ref)(null);
        var isInvalid = (0, vue_3.ref)(false);
        (0, core_1.onClickOutside)(dropdownRef, function () { return (isOpen.value = false); });
        (0, vue_3.onMounted)(function () {
            var _a;
            (_a = formkitSelectRef.value) === null || _a === void 0 ? void 0 : _a.node.on("message-added", function () { return __awaiter(_this, void 0, void 0, function () {
                var formKitState;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, (0, vue_3.nextTick)()];
                        case 1:
                            _b.sent();
                            formKitState = (_a = formkitSelectRef.value) === null || _a === void 0 ? void 0 : _a.node.context.state;
                            isInvalid.value = !formKitState.valid && formKitState.validationVisible;
                            return [2 /*return*/];
                    }
                });
            }); });
        });
        var getFocusedIndex = function () {
            return Object.keys(props.options).findIndex(function (key) { var _a; return key === ((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.getAttribute("data-key")); });
        };
        var focusOption = function (index) {
            if (!optionsListRef.value)
                return;
            if (index)
                optionsListRef.value.querySelectorAll("button")[index].focus();
        };
        // eslint-disable-next-line complexity
        var handleKeyUpDown = function (e, key) {
            if (isOpen.value)
                e.preventDefault();
            var focusedIndex = getFocusedIndex();
            var isFirstOrNoneFocused = focusedIndex < 1;
            var isLastFocused = focusedIndex >= Object.keys(props.options).length - 1;
            if (key === "up" && isFirstOrNoneFocused)
                return;
            if (key === "down" && isLastFocused)
                return;
            if (!isOpen.value || !isEm64.value)
                return;
            focusOption(key === "up" ? focusedIndex - 1 : focusedIndex + 1);
        };
        (0, core_1.onKeyStroke)("ArrowUp", function (e) { return handleKeyUpDown(e, "up"); });
        (0, core_1.onKeyStroke)("ArrowDown", function (e) { return handleKeyUpDown(e, "down"); });
        (0, core_1.onKeyStroke)("Escape", function () { return (isOpen.value = false); });
        var toggleOpen = function () {
            isOpen.value = !isOpen.value;
            if (isOpen.value && selectedIndex.value > -1)
                (0, vue_3.nextTick)(function () { return focusOption(selectedIndex.value); });
        };
        var toClassString = function (classObj) {
            return Object.entries(classObj)
                .reduce(function (classStr, _a) {
                var classes = _a[0], condition = _a[1];
                if (!condition)
                    return classStr;
                return "".concat(classStr, " ").concat(classes);
            }, "")
                .trim();
        };
        return function (_ctx, _cache) {
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (_ctx.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", {
                        key: 0,
                        for: _ctx.name,
                        class: "mb-4 block text-sm font-semibold"
                    }, (0, vue_2.toDisplayString)(_ctx.label), 9, _hoisted_2))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", {
                    ref_key: "dropdownRef",
                    ref: dropdownRef,
                    class: (0, vue_2.normalizeClass)(["relative", {
                            'text-gray-50': _ctx.disabled,
                            'text-black': isOpen.value || (!!(0, vue_2.unref)(selectedOption) && !_ctx.disabled),
                            'text-gray-80': !(0, vue_2.unref)(selectedOption) && !isOpen.value && !_ctx.disabled,
                            'focus-within:text-black hover:text-black': !_ctx.disabled,
                        }])
                }, [
                    (0, vue_2.createVNode)(_component_FormKit, {
                        id: _ctx.name,
                        ref_key: "formkitSelectRef",
                        ref: formkitSelectRef,
                        modelValue: (0, vue_2.unref)(selectedOption),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(selectedOption) ? (selectedOption).value = $event : null); }),
                        disabled: _ctx.disabled,
                        name: _ctx.name,
                        options: _ctx.options,
                        type: "select",
                        placeholder: _ctx.placeholder,
                        plugins: _ctx.plugins,
                        validation: _ctx.validation,
                        "validation-visibility": _ctx.validationVisibility,
                        "validation-messages": _ctx.validationMessages,
                        tabindex: (0, vue_2.unref)(isEm64) ? '-1' : undefined,
                        "aria-hidden": (0, vue_2.unref)(isEm64) ? 'true' : undefined,
                        "input-class": toClassString({
                            'bg-arrowDownError border-error focus:shadow-insetBottomError': isInvalid.value,
                            'focus:shadow-insetBottomBlack focus:bg-arrowDownBlack': !isInvalid.value,
                            'bg-arrowDownGray80 hover:bg-arrowDownBlack border-gray-80': !isInvalid.value && !isOpen.value && !(0, vue_2.unref)(selectedOption),
                            'bg-arrowDownBlack': !isInvalid.value && (isOpen.value || !!(0, vue_2.unref)(selectedOption)),
                            'shadow-insetBottomError': isInvalid.value && isOpen.value,
                            'pt-[1rem]': !!(0, vue_2.unref)(selectedOption) && !!_ctx.placeholder,
                        })
                    }, null, 8, ["id", "modelValue", "disabled", "name", "options", "placeholder", "plugins", "validation", "validation-visibility", "validation-messages", "tabindex", "aria-hidden", "input-class"]),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("button", {
                            disabled: _ctx.disabled,
                            class: (0, vue_2.normalizeClass)(["relative flex h-15 w-full select-none items-center rounded-t-lg border-b px-4 outline-none focus:text-black disabled:text-gray-50", {
                                    'border-error focus:shadow-insetBottomError': isInvalid.value,
                                    'focus:shadow-insetBottomBlack': !isInvalid.value,
                                    'shadow-insetBottomError': isOpen.value && isInvalid.value,
                                    'pointer-events-auto cursor-not-allowed border-gray-50': _ctx.disabled,
                                    'hover:bg-gray-40': !_ctx.disabled,
                                    'border-gray-80 hover:border-black hover:bg-gray-40': !isInvalid.value && !_ctx.disabled,
                                    'shadow-insetBottomBlack': isOpen.value && !isInvalid.value,
                                    'bg-gray-40': isOpen.value,
                                    'bg-gray-20': !isOpen.value,
                                }]),
                            type: "button",
                            tabindex: (0, vue_2.unref)(isEm64) ? '0' : '-1',
                            "aria-hidden": (0, vue_2.unref)(isEm64) ? undefined : 'true',
                            onClick: _cache[1] || (_cache[1] = function ($event) { return (toggleOpen()); })
                        }, [
                            (_ctx.icons && _ctx.icons[selectedIndex.value])
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SvgIcon_vue_1.default, {
                                    key: 0,
                                    class: (0, vue_2.normalizeClass)(["mr-2 h-5 w-5 shrink-0", { 'translate-y-[0.5rem]': _ctx.placeholder }]),
                                    "icon-name": _ctx.icons[selectedIndex.value]
                                }, null, 8, ["class", "icon-name"]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.createElementVNode)("div", {
                                class: (0, vue_2.normalizeClass)(["mr-1 flex-1 overflow-hidden whitespace-nowrap text-left", { 'pt-[1rem]': _ctx.placeholder }])
                            }, (0, vue_2.toDisplayString)((0, vue_2.unref)(selectedOption) ? _ctx.options[(0, vue_2.unref)(selectedOption)] : ""), 3),
                            (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                class: (0, vue_2.normalizeClass)(["h-5 w-5 shrink-0 transition-transform", { 'rotate-180': isOpen.value, 'text-error': isInvalid.value }]),
                                "icon-name": "arrow-down"
                            }, null, 8, ["class"])
                        ], 10, _hoisted_4),
                        (0, vue_2.createElementVNode)("ul", {
                            ref_key: "optionsListRef",
                            ref: optionsListRef,
                            class: (0, vue_2.normalizeClass)(["absolute left-0 top-full z-10 w-full text-black-primary shadow-4", { hidden: !isOpen.value }]),
                            style: { "clip-path": "inset(0 -20px -20px -20px)" }
                        }, [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.options, function (option, key, i) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", { key: key }, [
                                    (0, vue_2.createElementVNode)("button", {
                                        class: "flex h-12 w-full select-none items-center bg-white px-4 outline-none hover:bg-gray-40 hover:text-black focus:bg-gray-40 focus:text-black",
                                        "data-key": key,
                                        type: "button",
                                        onClick: function ($event) { return (selectOption(key)); }
                                    }, [
                                        (_ctx.icons && _ctx.icons[i])
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SvgIcon_vue_1.default, {
                                                key: 0,
                                                class: "mr-2 h-5 w-5 shrink-0",
                                                "icon-name": _ctx.icons[i]
                                            }, null, 8, ["icon-name"]))
                                            : (0, vue_2.createCommentVNode)("", true),
                                        (0, vue_2.createElementVNode)("div", {
                                            class: (0, vue_2.normalizeClass)(["flex-1 text-left", { 'ml-7': _ctx.icons && !_ctx.icons[i] }])
                                        }, (0, vue_2.toDisplayString)(option), 3)
                                    ], 8, _hoisted_5)
                                ]));
                            }), 128))
                        ], 2)
                    ]),
                    (0, vue_2.createElementVNode)("div", {
                        class: (0, vue_2.normalizeClass)(["pointer-events-none absolute left-4 top-0 flex h-15 origin-left select-none items-center transition-[opacity,_transform]", [
                                {
                                    'text-error': isInvalid.value,
                                    'peer-hover:text-black': !isInvalid.value && !_ctx.disabled,
                                    'text-gray-80': !isInvalid.value && (0, vue_2.unref)(selectedOption) && !isOpen.value,
                                },
                                isOpen.value || (0, vue_2.unref)(selectedOption)
                                    ? '-translate-y-[0.928571428571429rem] scale-75 opacity-100'
                                    : 'opacity-0 em64:opacity-100',
                            ]])
                    }, (0, vue_2.toDisplayString)(_ctx.placeholder), 3)
                ], 2)
            ]));
        };
    }
});
