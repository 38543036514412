"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["aria-label"];
var SvgIcon_vue_1 = require("../SvgIcon/SvgIcon.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'CloseButton',
    props: {
        inverted: { type: Boolean },
        ariaLabel: { default: "Schließen" }
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                type: "button",
                class: (0, vue_2.normalizeClass)(["absolute z-[15] grid h-8 w-8 place-content-center rounded-full border", [
                        _ctx.inverted
                            ? 'bg-white text-black-primary hover:bg-gray-40 active:bg-gray-60'
                            : 'border-black-primary bg-black-primary text-white hover:border-gray-90 hover:bg-gray-90 active:border-gray-80 active:bg-gray-80',
                    ]]),
                "aria-label": _ctx.ariaLabel,
                style: { "-webkit-tap-highlight-color": "transparent" }
            }, [
                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                    "icon-name": "close-fat",
                    class: "h-3.5 w-3.5"
                })
            ], 10, _hoisted_1));
        };
    }
});
