"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCollectionFromRoute = setCollectionFromRoute;
exports.rerouteIfNoPermission = rerouteIfNoPermission;
var vue_router_1 = require("vue-router");
var vue_1 = require("vue");
var ocm_object_1 = require("@ocm/services/ocm-object");
var SelectionPage_vue_1 = require("./views/SelectionPage.vue");
var HelpPage_vue_1 = require("./views/HelpPage.vue");
var LoginInfoPage_vue_1 = require("./views/LoginInfoPage.vue");
var OrderPage_vue_1 = require("./views/OrderPage.vue");
var DescriptionPage_vue_1 = require("./views/DescriptionPage.vue");
var PersonalDataPage_vue_1 = require("./views/PersonalDataPage.vue");
var ImageUploadPage_vue_1 = require("./views/ImageUploadPage.vue");
var ThankYouPage_vue_1 = require("./views/ThankYouPage.vue");
var use_trackingVirtualPageViews_1 = require("./composables/use-trackingVirtualPageViews");
var publications_query_1 = require("./queries/publications.query");
var use_polly_1 = require("./composables/use-polly");
var use_redirect_1 = require("./composables/use-redirect");
var use_restrictions_1 = require("./composables/use-restrictions");
var routeName_type_1 = require("./types/routeName.type");
var use_pageNavigation_1 = require("./composables/use-pageNavigation");
var routes = [
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/"),
        name: routeName_type_1.SELECTION_PAGE_NAME,
        component: SelectionPage_vue_1.default,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/:collectionId"),
        name: routeName_type_1.HELP_PAGE_NAME,
        component: HelpPage_vue_1.default,
        props: true,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/:collectionId/loginInfo"),
        name: routeName_type_1.LOGIN_INFO_PAGE_NAME,
        component: LoginInfoPage_vue_1.default,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/:collectionId/order"),
        name: routeName_type_1.ORDER_PAGE_NAME,
        component: OrderPage_vue_1.default,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/:collectionId/imageupload"),
        name: routeName_type_1.IMAGE_UPLOAD_PAGE_NAME,
        component: ImageUploadPage_vue_1.default,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/:collectionId/description"),
        name: routeName_type_1.DESCRIPTION_PAGE_NAME,
        component: DescriptionPage_vue_1.default,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/:collectionId/customer"),
        name: routeName_type_1.PERSONAL_INFO_PAGE_NAME,
        component: PersonalDataPage_vue_1.default,
    },
    {
        path: "".concat(ocm_object_1.contextPath, "/:contactBasePath/confirmation"),
        name: routeName_type_1.THANK_YOU_PAGE_NAME,
        component: ThankYouPage_vue_1.default,
    },
];
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(),
    routes: routes,
    scrollBehavior: function () {
        if (window.innerWidth <= 720) {
            return {
                el: "#contact",
                top: 60, // px above the element, offset the global header
            };
        }
        return { top: 0 };
    },
});
router.beforeEach(function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!publications_query_1.publicationData.value) return [3 /*break*/, 2];
                return [4 /*yield*/, (0, publications_query_1.getPublications)()];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); });
router.afterEach(function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, use_redirect_1.rerouteIfDeepLink)()];
            case 1:
                _a.sent();
                setCollectionFromRoute();
                (0, vue_1.nextTick)(function () { return __awaiter(void 0, void 0, void 0, function () {
                    var routeName, currentCollectionId;
                    var _a;
                    return __generator(this, function (_b) {
                        routeName = router.currentRoute.value.name;
                        currentCollectionId = (_a = use_polly_1.currentCollection.value) === null || _a === void 0 ? void 0 : _a.id;
                        rerouteIfNameNotFound(routeName);
                        rerouteIfNoPermission(routeName, currentCollectionId);
                        rerouteToFirstFormStepIfEndpointDoesNotExistInCurrentFlow(routeName, currentCollectionId);
                        (0, use_trackingVirtualPageViews_1.trackVirtualPageView)();
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
        }
    });
}); });
function setCollectionFromRoute() {
    var collectionIdParams = router.currentRoute.value.params.collectionId;
    var isCollectionObject = typeof collectionIdParams === "object";
    var collectionIdParam = isCollectionObject ? collectionIdParams[0] : collectionIdParams;
    if (collectionIdParam) {
        use_polly_1.currentCollection.value = (0, publications_query_1.getCollectionFromId)(collectionIdParam);
    }
}
function rerouteIfNoPermission(routeName, collectionId) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, use_restrictions_1.hasUserPermission)()];
                case 1:
                    if (!(_a.sent()) && routeName && routeName_type_1.FORM_ROUTE_NAMES.includes(routeName)) {
                        router.replace({ name: routeName_type_1.LOGIN_INFO_PAGE_NAME, params: { collectionId: collectionId } });
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function rerouteToFirstFormStepIfEndpointDoesNotExistInCurrentFlow(routeName, collectionId) {
    if (routeName &&
        routeName_type_1.FORM_ROUTE_NAMES.includes(routeName) &&
        !use_polly_1.currentFormFlow.value.includes(routeName)) {
        (0, use_pageNavigation_1.startContactForm)(collectionId, true);
    }
}
function rerouteIfNameNotFound(routeName) {
    if (!routeName) {
        var contactBasePath = ocm_object_1.language === "de" ? "kontakt" : "contact";
        router.replace({ name: routeName_type_1.SELECTION_PAGE_NAME, params: { contactBasePath: contactBasePath } });
    }
}
exports.default = router;
