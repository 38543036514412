"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "inline-grid w-fit" };
var vue_3 = require("vue");
var SvgIcon_vue_1 = require("../SvgIcon/SvgIcon.vue");
var AccessibleModal_vue_1 = require("./AccessibleModal.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'InfoModal',
    props: {
        headline: {},
        narrow: { type: Boolean },
        iconSize: { default: "4.5" },
        iconLabel: {},
        isVue2: { type: Boolean }
    },
    emits: ["opening"],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var emit = __emit;
        var dialogEl = (0, vue_3.ref)(null);
        var openModal = function () {
            var _a;
            (_a = dialogEl.value) === null || _a === void 0 ? void 0 : _a.open();
            emit("opening");
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("button", {
                    type: "button",
                    class: "font-medium underline",
                    onClick: openModal
                }, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "trigger", {}, function () { return [
                        (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                            "icon-name": "info",
                            class: (0, vue_2.normalizeClass)("h-".concat(_ctx.iconSize, " w-").concat(_ctx.iconSize)),
                            title: _ctx.iconLabel
                        }, null, 8, ["class", "title"])
                    ]; })
                ]),
                (0, vue_2.createVNode)(AccessibleModal_vue_1.default, {
                    ref_key: "dialogEl",
                    ref: dialogEl,
                    headline: _ctx.headline,
                    narrow: _ctx.narrow,
                    "is-vue2": _ctx.isVue2
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ]; }),
                    _: 3
                }, 8, ["headline", "narrow", "is-vue2"])
            ]));
        };
    }
});
