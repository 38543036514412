"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["disabled"];
var _hoisted_2 = ["aria-hidden"];
var _hoisted_3 = { class: "p-4" };
var vue_3 = require("vue");
var SvgIcon_vue_1 = require("../SvgIcon/SvgIcon.vue");
var TransitionExpand_vue_1 = require("./TransitionExpand.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SharedExpandable',
    props: {
        disabled: { type: Boolean },
        isExpanded: { type: Boolean },
        title: {}
    },
    emits: ["header-clicked"],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        var isExpanded = (0, vue_3.ref)();
        var isExpanding = (0, vue_3.ref)(false);
        var emit = __emit;
        (0, vue_3.onMounted)(function () {
            isExpanded.value = props.isExpanded;
        });
        var handleClick = function () {
            emit("header-clicked");
            if (isExpanded.value) {
                isExpanded.value = false;
                return;
            }
            isExpanded.value = true;
            isExpanding.value = true;
            collapseOthers();
        };
        var collapseOthers = (0, vue_3.inject)("collapseOthers", function () { });
        var isCollapsing = (0, vue_3.inject)("isCollapsing", (0, vue_3.ref)(false));
        (0, vue_3.watch)(function () { return isCollapsing.value; }, function (newVal) {
            if (!newVal)
                return;
            isExpanded.value = isExpanding.value;
            isExpanding.value = false;
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createElementVNode)("dt", null, [
                    (0, vue_2.createElementVNode)("button", {
                        class: (0, vue_2.normalizeClass)(["relative flex w-full select-none justify-between border-b border-gray-30 p-4 text-left text-sm outline-none before:absolute before:-left-[2px] before:-top-[2px] before:z-10 before:h-[calc(100%_+_4px)] before:w-[calc(100%_+_4px)] before:rounded before:border-2 before:border-transparent focus-visible:before:border-black-primary", {
                                'bg-gray-30': isExpanded.value,
                                'cursor-not-allowed text-gray-50': _ctx.disabled,
                                'text-black-primary supportsHover:hover:bg-gray-30': !_ctx.disabled,
                            }]),
                        disabled: _ctx.disabled,
                        style: { "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)" },
                        onClick: handleClick
                    }, [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.title) + " ", 1),
                        (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                            class: "ml-4 h-5 w-5 shrink-0",
                            "icon-name": isExpanded.value ? 'minus-thin' : 'plus-thin'
                        }, null, 8, ["icon-name"])
                    ], 10, _hoisted_1)
                ]),
                (0, vue_2.createVNode)(TransitionExpand_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("dd", {
                            class: "border-b border-gray-30 text-sm",
                            "aria-hidden": isExpanded.value ? 'false' : 'true'
                        }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_3, [
                                (0, vue_2.renderSlot)(_ctx.$slots, "default")
                            ])
                        ], 8, _hoisted_2), [
                            [vue_2.vShow, isExpanded.value]
                        ])
                    ]; }),
                    _: 3
                })
            ]));
        };
    }
});
