"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackImageCompressionError = exports.trackBackendError = exports.trackSearchLoadCaptchaUnforeseenError = exports.trackSearchSubmitUnforeseenError = exports.trackSearchTermNotLongEnough = exports.trackImageWrongType = exports.trackImageSizeToLarge = void 0;
exports.useErrorTracking = useErrorTracking;
var vue_1 = require("vue");
var pushGAevent_1 = require("@/services/pushGAevent");
// Please keep the Confluence documentation up-to-date: https://redbox.outletcity.com/confluence/display/TUB/FR+-+Custom-Tracking
function useErrorTracking() {
    var errorMessage = (0, vue_1.ref)();
    var changeErrorMsg = function (formKitMessage) {
        var messageType = formKitMessage.key;
        var message = formKitMessage.value;
        if ((messageType === "rule_required" || messageType === "rule_matches") && typeof message === "string") {
            errorMessage.value = message;
        }
        else if (messageType === "validating" && message === true) {
            errorMessage.value = undefined;
        }
        else if (messageType === "submitted") {
            trackFormErrors();
        }
    };
    var trackFormErrors = function () {
        if (!errorMessage.value)
            return;
        (0, pushGAevent_1.pushGAevent)("form_errors", errorMessage.value);
    };
    var trackingPlugin = function (node) {
        node.on("message-added", function (message) {
            changeErrorMsg(message.payload);
        });
        node.on("message-updated", function (message) {
            changeErrorMsg(message.payload);
        });
    };
    return {
        trackingPlugin: trackingPlugin,
    };
}
var trackImageSizeToLarge = function (t) {
    (0, pushGAevent_1.pushGAevent)("form_errors", t("imageUploadPage.fileSizeLimitInfo"));
};
exports.trackImageSizeToLarge = trackImageSizeToLarge;
var trackImageWrongType = function (t) {
    (0, pushGAevent_1.pushGAevent)("form_errors", t("imageUploadPage.errorFileTyp"));
};
exports.trackImageWrongType = trackImageWrongType;
var trackSearchTermNotLongEnough = function (searchTerm) {
    (0, pushGAevent_1.pushGAevent)("faq_search_error_response", "search_term_not_long_enough: ".concat(searchTerm));
};
exports.trackSearchTermNotLongEnough = trackSearchTermNotLongEnough;
var trackSearchSubmitUnforeseenError = function (errorResponse) {
    (0, exports.trackBackendError)("search_submit", errorResponse);
};
exports.trackSearchSubmitUnforeseenError = trackSearchSubmitUnforeseenError;
var trackSearchLoadCaptchaUnforeseenError = function (errorResponse) {
    (0, exports.trackBackendError)("load_captcha", errorResponse);
};
exports.trackSearchLoadCaptchaUnforeseenError = trackSearchLoadCaptchaUnforeseenError;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var trackBackendError = function (error_action, errorResponse) {
    (0, pushGAevent_1.pushGAevent)("backend_error", "".concat(error_action, ": ").concat(errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.status, "_").concat(errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.statusText));
};
exports.trackBackendError = trackBackendError;
var trackImageCompressionError = function (size, fileType) {
    (0, pushGAevent_1.pushGAevent)("image_compression", "size_".concat(size, "_file_").concat(fileType));
};
exports.trackImageCompressionError = trackImageCompressionError;
