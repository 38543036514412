"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackVirtualPageView = void 0;
var dataLayer_js_1 = require("@ocm/services/dataLayer.js");
var trackVirtualPageView = function () {
    var _a;
    if (window.recommendationData) {
        (0, dataLayer_js_1.default)({ event: "virtualPageView", Recommendation: (_a = window.recommendationData) === null || _a === void 0 ? void 0 : _a.Recommendation });
    }
};
exports.trackVirtualPageView = trackVirtualPageView;
