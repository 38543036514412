"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["aria-labelledby"];
var _hoisted_2 = { class: "flex flex-col overflow-y-auto" };
var _hoisted_3 = ["id"];
var _hoisted_4 = {
    key: 2,
    class: "flex justify-end px-4 pb-4"
};
var vue_3 = require("vue");
var CloseButton_vue_1 = require("../CloseButton/CloseButton.vue");
var SharedButton_vue_1 = require("../SharedButton.vue");
var services_modal_1 = require("../../../services/services.modal");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SharedDialog',
    props: {
        closeActionButton: { type: Boolean },
        closeActionButtonText: { default: "Ok" },
        fullMaxHeight: { type: Boolean },
        headline: {},
        narrow: { type: Boolean },
        noBodyPadding: { type: Boolean },
        noScrollbar: { type: Boolean },
        preventDismiss: { type: Boolean },
        wide: { type: Boolean }
    },
    emits: ["closing", "closed"],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var emit = __emit;
        var uuid = (Math.random() + 1).toString(36).substring(2);
        var slideIn = (0, vue_3.ref)(false);
        var dialogEl = (0, vue_3.ref)(null);
        var open = function () {
            var _a, _b;
            slideIn.value = true;
            (_a = dialogEl.value) === null || _a === void 0 ? void 0 : _a.showModal();
            (_b = dialogEl.value) === null || _b === void 0 ? void 0 : _b.focus();
        };
        var close = function (triggeredBy) {
            if (triggeredBy === void 0) { triggeredBy = ""; }
            slideIn.value = false;
            emit("closing", triggeredBy);
        };
        var onAnimationEnd = function (_a) {
            var _b;
            var animationName = _a.animationName;
            if (animationName !== "dialogSlideOut")
                return;
            (_b = dialogEl.value) === null || _b === void 0 ? void 0 : _b.close();
            emit("closed");
        };
        (0, vue_3.onMounted)(function () {
            var _a, _b;
            (_a = dialogEl.value) === null || _a === void 0 ? void 0 : _a.addEventListener("animationend", onAnimationEnd);
            (_b = dialogEl.value) === null || _b === void 0 ? void 0 : _b.addEventListener("animationcancel", onAnimationEnd);
        });
        (0, vue_3.onBeforeUnmount)(function () {
            var _a, _b;
            (_a = dialogEl.value) === null || _a === void 0 ? void 0 : _a.removeEventListener("animationend", onAnimationEnd);
            (_b = dialogEl.value) === null || _b === void 0 ? void 0 : _b.removeEventListener("animationcancel", onAnimationEnd);
        });
        __expose({ open: open, close: close });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("dialog", {
                ref_key: "dialogEl",
                ref: dialogEl,
                class: (0, vue_2.normalizeClass)(["shared-dialog tw", [
                        slideIn.value ? 'dialog-animate-in' : 'dialog-animate-out',
                        {
                            'is-narrow': _ctx.narrow,
                            'is-wide': _ctx.wide,
                            'max-h-full': _ctx.fullMaxHeight,
                        },
                    ]]),
                style: (0, vue_2.normalizeStyle)({ '--animation-duration': (0, vue_2.unref)(services_modal_1.animationDurationMs) }),
                "aria-labelledby": _ctx.headline ? (0, vue_2.unref)(uuid) : undefined,
                tabindex: "-1",
                onCancel: _cache[2] || (_cache[2] = (0, vue_2.withModifiers)(function ($event) { return (close('escapeKey')); }, ["prevent"])),
                onClick: _cache[3] || (_cache[3] = (0, vue_2.withModifiers)(function ($event) { return (!_ctx.preventDismiss && close('overlay')); }, ["self", "stop"]))
            }, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (!_ctx.preventDismiss)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(CloseButton_vue_1.default, {
                            key: 0,
                            class: "right-4 top-4",
                            "data-test-dialog-close-button": "",
                            onClick: _cache[0] || (_cache[0] = function ($event) { return (close('closeButton')); })
                        }))
                        : (0, vue_2.createCommentVNode)("", true),
                    (_ctx.headline)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("h2", {
                            key: 1,
                            id: (0, vue_2.unref)(uuid),
                            class: "ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words",
                            "data-test-modal-headline": ""
                        }, (0, vue_2.toDisplayString)(_ctx.headline), 9, _hoisted_3))
                        : (0, vue_2.createCommentVNode)("", true),
                    (0, vue_2.createElementVNode)("div", {
                        class: (0, vue_2.normalizeClass)(["pb-4", [
                                _ctx.noScrollbar ? 'flex flex-col overflow-y-auto' : 'o-scrollbar overscroll-contain',
                                {
                                    'lg-down:pb-9': !_ctx.closeActionButton,
                                    'px-4 pt-6': !_ctx.noBodyPadding,
                                },
                            ]])
                    }, [
                        (_ctx.$slots.headerblock)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                key: 0,
                                class: (0, vue_2.normalizeClass)(["-mt-5 bg-gray-20 px-4 py-9", { '-mx-4': !_ctx.noBodyPadding }])
                            }, [
                                (0, vue_2.renderSlot)(_ctx.$slots, "headerblock")
                            ], 2))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ], 2),
                    (_ctx.closeActionButton)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_4, [
                            (0, vue_2.renderSlot)(_ctx.$slots, "footer"),
                            (0, vue_2.createVNode)(SharedButton_vue_1.default, {
                                class: "min-w-[calc(var(--spacing-base-unit)*25)]",
                                onClick: _cache[1] || (_cache[1] = function ($event) { return (close('actionButton')); })
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.closeActionButtonText), 1)
                                ]; }),
                                _: 1
                            })
                        ]))
                        : (0, vue_2.createCommentVNode)("", true)
                ])
            ], 46, _hoisted_1));
        };
    }
});
