"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "tw border-t border-gray-30" };
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SharedExpandableGroup',
    props: {
        stayOpen: { type: Boolean }
    },
    setup: function (__props) {
        var props = __props;
        var isCollapsing = (0, vue_3.ref)(false);
        (0, vue_3.provide)("isCollapsing", isCollapsing);
        (0, vue_3.provide)("collapseOthers", function () {
            if (props.stayOpen)
                return;
            isCollapsing.value = true;
            (0, vue_3.nextTick)(function () { return (isCollapsing.value = false); });
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("dl", _hoisted_1, [
                (0, vue_2.renderSlot)(_ctx.$slots, "default")
            ]));
        };
    }
});
