"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetStepToLocalStorage = exports.getStepLocalStorage = exports.saveStepToLocalStorage = void 0;
var use_polly_1 = require("@/composables/use-polly");
var CONTACTCENTER_STEP = "contactCenterStep";
var saveStepToLocalStorage = function () { var _a; return localStorage.setItem(CONTACTCENTER_STEP, use_polly_1.currentCollection.value ? (_a = use_polly_1.currentCollection.value) === null || _a === void 0 ? void 0 : _a.id : ""); };
exports.saveStepToLocalStorage = saveStepToLocalStorage;
var getStepLocalStorage = function () { return localStorage.getItem(CONTACTCENTER_STEP); };
exports.getStepLocalStorage = getStepLocalStorage;
var resetStepToLocalStorage = function () { return localStorage.setItem(CONTACTCENTER_STEP, ""); };
exports.resetStepToLocalStorage = resetStepToLocalStorage;
