"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.rerouteIfDeepLink = exports.triggerReauthOrLogin = exports.redirectLocalStorage = void 0;
var ocm_mercurius_messenger_1 = require("ocm_mercurius_messenger");
var services_constants_js_1 = require("@ocm/services/services.constants.js");
var ocm_object_1 = require("@ocm/services/ocm-object");
var authlevel_1 = require("@/services/authlevel");
var localStorage_1 = require("@/services/localStorage");
var checkAuth_1 = require("@/services/checkAuth");
var use_polly_1 = require("./use-polly");
var use_pageNavigation_1 = require("./use-pageNavigation");
var routeName_type_1 = require("@/types/routeName.type");
var publications_query_1 = require("@/queries/publications.query");
var router_1 = require("@/router");
// Reauth/Log-in
var redirectLocalStorage = function () { return __awaiter(void 0, void 0, Promise, function () {
    var localStoreStep;
    var _a;
    return __generator(this, function (_b) {
        localStoreStep = (0, localStorage_1.getStepLocalStorage)();
        if (localStoreStep) {
            use_polly_1.currentCollection.value = (0, publications_query_1.getCollectionFromId)(localStoreStep);
            (0, use_pageNavigation_1.startContactForm)((_a = use_polly_1.currentCollection.value) === null || _a === void 0 ? void 0 : _a.id, true);
            (0, localStorage_1.resetStepToLocalStorage)();
        }
        return [2 /*return*/];
    });
}); };
exports.redirectLocalStorage = redirectLocalStorage;
var goToReauthPage = function () {
    window.location.href = "".concat(ocm_object_1.contextPath, "/my-account/re-auth/");
};
var openLoginlayer = function () {
    ocm_mercurius_messenger_1.default.publish(services_constants_js_1.messengerPublicationTypes.REQUEST_LOGIN);
};
var triggerReauthOrLogin = function () {
    (0, localStorage_1.saveStepToLocalStorage)();
    var authLevel = checkAuth_1.currentAuthLevel.value;
    if ((0, authlevel_1.isRoleRememberMe)(authLevel) || (0, authlevel_1.isRoleEasyAuth)(authLevel)) {
        goToReauthPage();
    }
    if ((0, authlevel_1.isRoleAnonymous)(authLevel) || (0, authlevel_1.isRoleCoop)(authLevel)) {
        ocm_mercurius_messenger_1.default.subscribe(services_constants_js_1.messengerPublicationTypes.ABORTED_LOGIN, localStorage_1.resetStepToLocalStorage);
        openLoginlayer();
    }
};
exports.triggerReauthOrLogin = triggerReauthOrLogin;
// Deep links
var rerouteIfDeepLink = function () { return __awaiter(void 0, void 0, Promise, function () {
    var url, params, collectionIdFromParam, faqIdFromParam;
    return __generator(this, function (_a) {
        url = new URL(window.location.href);
        params = new URLSearchParams(url.search);
        collectionIdFromParam = params.get("collection");
        faqIdFromParam = params.get("faq");
        if (collectionIdFromParam) {
            redirectToParam(collectionIdFromParam, faqIdFromParam);
        }
        return [2 /*return*/];
    });
}); };
exports.rerouteIfDeepLink = rerouteIfDeepLink;
var redirectToParam = function (collectionIdFromParam, faqIdFromParam) {
    var collection = (0, publications_query_1.getCollectionFromId)(collectionIdFromParam);
    if (collection) {
        use_polly_1.currentCollection.value = collection;
        router_1.default.replace({
            name: routeName_type_1.HELP_PAGE_NAME,
            params: {
                collectionId: collectionIdFromParam,
            },
            query: { faq: faqIdFromParam },
        });
    }
};
