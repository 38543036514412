"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areAllStepsCompleted = exports.completedSteps = void 0;
exports.useRerouteToValidStep = useRerouteToValidStep;
var vue_1 = require("vue");
var use_polly_1 = require("./use-polly");
var router_1 = require("@/router");
var use_messageData_1 = require("./use-messageData");
exports.completedSteps = (0, vue_1.ref)(new Set());
exports.areAllStepsCompleted = (0, vue_1.computed)(function () { return exports.completedSteps.value.size === use_polly_1.currentFormFlow.value.length; });
function useRerouteToValidStep(formKitRef) {
    var currentStep = router_1.default.currentRoute.value.name;
    var currentStepIndex = (0, vue_1.computed)(function () { return use_polly_1.currentFormFlow.value.findIndex(function (stepName) { return stepName === currentStep; }); });
    (0, vue_1.watchEffect)(function () {
        updateCompletedSteps();
    });
    addEventListener("beforeunload", function (event) {
        if ((0, use_messageData_1.isContactFormDirty)())
            event.preventDefault();
    });
    rerouteToFirstIncompleteStep();
    (0, vue_1.watch)(use_polly_1.currentFormFlow, rerouteToFirstIncompleteStep);
    function updateCompletedSteps() {
        var _a, _b, _c, _d;
        var formValidityState = (_d = (_c = (_b = (_a = formKitRef.value) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.context) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.valid;
        if (currentStep === null || currentStep === undefined)
            return;
        if (formValidityState) {
            exports.completedSteps.value.add(currentStep);
        }
        else {
            exports.completedSteps.value.delete(currentStep);
        }
    }
    function rerouteToFirstIncompleteStep() {
        var firstIncompleteStep = getFirstIncompleteStepIndex();
        if (firstIncompleteStep > -1 && currentStepIndex.value > firstIncompleteStep) {
            var routeName = use_polly_1.currentFormFlow.value[firstIncompleteStep];
            if (routeName)
                router_1.default.replace({ name: routeName });
        }
    }
    function getFirstIncompleteStepIndex() {
        return use_polly_1.currentFormFlow.value.findIndex(function (stepName) { return !exports.completedSteps.value.has(stepName); });
    }
}
