"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.acceptedFiles = exports.getEmailImages = exports.imageUploadSizeOverLimit = exports.formatFileSize = exports.isAnyImageCompressing = exports.invalidTypeFieldNames = void 0;
var vue_1 = require("vue");
var use_messageData_1 = require("@/composables/use-messageData");
exports.invalidTypeFieldNames = (0, vue_1.ref)(new Set());
exports.isAnyImageCompressing = (0, vue_1.ref)(false);
var formatFileSize = function (bytes) {
    var sufixes = ["B", "kB", "MB", "GB", "TB"];
    var i = Math.floor(Math.log(bytes) / Math.log(1000));
    return "".concat((bytes / Math.pow(1000, i)).toFixed(2), " ").concat(sufixes[i]);
};
exports.formatFileSize = formatFileSize;
var getFileSize = function (input) {
    var _a, _b;
    if (input === null || input === void 0 ? void 0 : input.value[0])
        return (_b = (_a = input === null || input === void 0 ? void 0 : input.value[0]) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.size;
    return 0;
};
exports.imageUploadSizeOverLimit = (0, vue_1.computed)(function () {
    if (exports.isAnyImageCompressing.value)
        return false;
    var shoeSize = use_messageData_1.isShoe.value ? getFileSize(use_messageData_1.soleImage) : 0;
    var size = getFileSize(use_messageData_1.articleImage) +
        getFileSize(use_messageData_1.defectImage) +
        shoeSize +
        getFileSize(use_messageData_1.receiptImage) +
        getFileSize(use_messageData_1.optionalImage1) +
        getFileSize(use_messageData_1.optionalImage2);
    return size > 5 * 1024 * 1024; // 5 MB in bytes
});
var getBase64 = function (image) {
    return new Promise(function (resolve, reject) {
        var _a;
        var file = (_a = image.value[0]) === null || _a === void 0 ? void 0 : _a.file;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () { return resolve(reader.result); };
        reader.onerror = function (error) { return reject(error); };
    });
};
var getEmailImages = function (Images) { return __awaiter(void 0, void 0, Promise, function () {
    var images, _i, Images_1, image, imageValue, imageBase64, objJsonStr;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                images = [];
                _i = 0, Images_1 = Images;
                _a.label = 1;
            case 1:
                if (!(_i < Images_1.length)) return [3 /*break*/, 4];
                image = Images_1[_i];
                imageValue = image.value[0];
                if (!((imageValue === null || imageValue === void 0 ? void 0 : imageValue.file) && (imageValue === null || imageValue === void 0 ? void 0 : imageValue.name))) return [3 /*break*/, 3];
                return [4 /*yield*/, getBase64(image)];
            case 2:
                imageBase64 = _a.sent();
                objJsonStr = JSON.stringify(imageBase64);
                images.push(setEmailImage(imageValue === null || imageValue === void 0 ? void 0 : imageValue.name, btoa(objJsonStr).toString()));
                _a.label = 3;
            case 3:
                _i++;
                return [3 /*break*/, 1];
            case 4: return [2 /*return*/, images];
        }
    });
}); };
exports.getEmailImages = getEmailImages;
exports.acceptedFiles = ".jpg, .jpeg, .png";
var setEmailImage = function (name, img) {
    return ({
        name: name,
        type: "article",
        img: img,
    });
};
