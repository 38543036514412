"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 0,
    class: "grid auto-rows-[minmax(0,84px)] grid-cols-2 gap-4"
};
var _hoisted_2 = { key: 1 };
var _hoisted_3 = {
    key: 2,
    class: "grid auto-rows-[minmax(0,84px)] grid-cols-2 gap-4"
};
var vue_3 = require("vue");
var BasicSkeleton_vue_1 = require("@SharedVueComponents/BasicSkeleton/BasicSkeleton.vue");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var publications_query_1 = require("@/queries/publications.query");
var BaseCard_vue_1 = require("@/components/BaseCard.vue");
var search_query_1 = require("@/queries/search.query");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BasePublications',
    setup: function (__props) {
        var redirectNoPublications = function () {
            var _a;
            if (!((_a = publications_query_1.publicationData.value) === null || _a === void 0 ? void 0 : _a.publication)) {
                (0, use_pageNavigation_1.startContactForm)("fallback");
            }
        };
        var isEmptySearchResult = (0, vue_3.computed)(function () { return !search_query_1.resultResponse.value || search_query_1.resultResponse.value.length === 0; });
        (0, vue_3.onBeforeMount)(function () {
            redirectNoPublications();
        });
        return function (_ctx, _cache) {
            var _a, _b;
            return ((0, vue_2.unref)(publications_query_1.fetching))
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(8, function (n) {
                        return (0, vue_2.createVNode)(BasicSkeleton_vue_1.default, {
                            key: "skeleton-".concat(n),
                            class: "rounded-lg"
                        });
                    }), 64))
                ]))
                : ((0, vue_2.unref)(publications_query_1.error))
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.$t("generalError")), 1))
                    : (((_b = (_a = (0, vue_2.unref)(publications_query_1.publicationData)) === null || _a === void 0 ? void 0 : _a.publication) === null || _b === void 0 ? void 0 : _b.collections) && isEmptySearchResult.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(publications_query_1.publicationData).publication.collections, function (collection, i) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(BaseCard_vue_1.default, {
                                    key: i,
                                    collection: collection
                                }, null, 8, ["collection"]));
                            }), 128))
                        ]))
                        : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
