"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var use_messageData_1 = require("@/composables/use-messageData");
var use_trackingErrors_1 = require("@/composables/use-trackingErrors");
var HeadlineText_vue_1 = require("@/components/HeadlineText.vue");
var vue_3 = require("vue");
var use_autofill_1 = require("@/composables/use-autofill");
var vue_i18n_1 = require("vue-i18n");
var use_formValidity_1 = require("@/composables/use-formValidity");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DescriptionPage',
    setup: function (__props) {
        var _this = this;
        var t = (0, vue_i18n_1.useI18n)({}).t;
        var formRef = (0, vue_3.ref)();
        (0, vue_3.onMounted)(function () {
            (0, use_formValidity_1.useRerouteToValidStep)(formRef);
        });
        (0, vue_3.onBeforeMount)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // additional early call to pre-fetch contact data
                    return [4 /*yield*/, (0, use_autofill_1.getAndAutofillContactData)(t)];
                    case 1:
                        // additional early call to pre-fetch contact data
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return function (_ctx, _cache) {
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(HeadlineText_vue_1.default, null, {
                    sub: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("descriptionPage.help")), 1)
                    ]; }),
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("descriptionPage.headline")) + " ", 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(_component_FormKit, {
                    ref_key: "formRef",
                    ref: formRef,
                    type: "form",
                    "submit-label": _ctx.$t('next'),
                    config: { validationVisibility: 'submit' },
                    onSubmit: _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.unref)(use_pageNavigation_1.goToNextFormStep)()); })
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_FormKit, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.description),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.description) ? (use_messageData_1.description).value = $event : null); }),
                            type: "textarea",
                            "outer-class": "$reset mb-20",
                            validation: "required",
                            placeholder: _ctx.$t('descriptionPage.placeholder'),
                            plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                            name: _ctx.$t('descriptionPage.description')
                        }, null, 8, ["modelValue", "placeholder", "plugins", "name"])
                    ]; }),
                    _: 1
                }, 8, ["submit-label"])
            ]));
        };
    }
});
