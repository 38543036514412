"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ocmChipCheckbox = exports.ocmChipRadio = void 0;
var inputs_1 = require("@formkit/inputs");
var chip_js_1 = require("../styles/chip.js");
exports.ocmChipRadio = getChip("radio", inputs_1.radios);
exports.ocmChipCheckbox = getChip("checkbox", inputs_1.checkboxes);
function getChip(tagName, typeInstance) {
    return {
        schema: (0, inputs_1.outer)((0, inputs_1.$if)("$options == undefined", 
        /**
         * Single radio structure.
         */
        (0, inputs_1.boxWrapper)((0, inputs_1.inner)((0, inputs_1.prefix)(), (0, inputs_1.box)(), (0, inputs_1.decorator)(), (0, inputs_1.suffix)()), (0, inputs_1.$if)("$label", (0, inputs_1.boxLabel)("$label"))), 
        /**
         * Multi radio structure.
         */
        (0, inputs_1.fieldset)((0, inputs_1.legend)("$label"), (0, inputs_1.help)("$help"), (0, inputs_1.$extend)((0, inputs_1.boxOptions)((0, inputs_1.boxOption)((0, inputs_1.boxWrapper)((0, inputs_1.inner)((0, inputs_1.prefix)(), (0, inputs_1.$extend)((0, inputs_1.box)(), {
            bind: "$option.attrs",
            attrs: {
                id: "$option.attrs.id",
                value: "$option.value",
                checked: "$fns.isChecked($option.value)",
                type: tagName,
                class: "peer hidden",
            },
        }), (0, inputs_1.$extend)((0, inputs_1.boxLabel)(), {
            attrs: {
                class: chip_js_1.chipBasicClasses,
            },
            children: [
                {
                    $el: "svg",
                    if: "$option.iconFirst",
                    attrs: {
                        class: "svg-icon" + chip_js_1.chipBeforeIconClasses,
                    },
                    children: [
                        {
                            $el: "use",
                            attrs: {
                                href: "$option.iconFirst",
                                "xlink:href": "$option.iconFirst",
                            },
                        },
                    ],
                },
                {
                    $el: "span",
                    attrs: {
                    // class: "$classes.message",
                    },
                    children: "$option.label",
                },
                {
                    $el: "svg",
                    if: "$option.iconLast",
                    attrs: {
                        class: "svg-icon" + chip_js_1.chipAfterIconClasses,
                    },
                    children: [
                        {
                            $el: "use",
                            attrs: {
                                href: "$option.iconLast",
                                "xlink:href": "$option.iconLast",
                            },
                        },
                    ],
                },
            ],
        }), (0, inputs_1.decorator)(), (0, inputs_1.suffix)())), (0, inputs_1.boxHelp)("$option.help"))), {
            attrs: { class: chip_js_1.chipContainerClasses },
        }))), 
        // Help text only goes under the input when it is a single.
        (0, inputs_1.$if)("$options == undefined && $help", (0, inputs_1.help)("$help")), (0, inputs_1.messages)((0, inputs_1.message)("$message.value"))),
        /**
         * The type of node, can be a list, group, or input.
         */
        type: "input",
        /**
         * An array of extra props to accept for this input.
         */
        props: ["options", "onValue", "offValue", "chipType"],
        /**
         * Forces node.props.type to be this explicit value.
         */
        // forceTypeProp: 'radio',
        /**
         * Additional features that should be added to your input
         */
        features: [inputs_1.options, typeInstance],
    };
}
