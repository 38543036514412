"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomerNumber = exports.returnEmailObject = exports.optionalImage2 = exports.optionalImage1 = exports.receiptImage = exports.soleImage = exports.defectImage = exports.articleImage = exports.isShoe = exports.salutation = exports.firstName = exports.lastName = exports.emailDisabled = exports.email = exports.phone = exports.description = exports.orderNumber = void 0;
exports.resetInputData = resetInputData;
exports.isContactFormDirty = isContactFormDirty;
var vue_1 = require("vue");
var use_polly_1 = require("@/composables/use-polly");
var use_imageUpload_1 = require("@/composables/use-imageUpload");
var ocm_object_1 = require("@ocm/services/ocm-object");
var use_restrictions_1 = require("./use-restrictions");
var use_autofill_1 = require("./use-autofill");
exports.orderNumber = (0, vue_1.ref)(null);
exports.description = (0, vue_1.ref)("");
exports.phone = (0, vue_1.ref)("");
exports.email = (0, vue_1.ref)("");
exports.emailDisabled = (0, vue_1.ref)(false);
exports.lastName = (0, vue_1.ref)("");
exports.firstName = (0, vue_1.ref)("");
exports.salutation = (0, vue_1.ref)("");
exports.isShoe = (0, vue_1.ref)(false);
exports.articleImage = (0, vue_1.ref)([]);
exports.defectImage = (0, vue_1.ref)([]);
exports.soleImage = (0, vue_1.ref)([]);
exports.receiptImage = (0, vue_1.ref)([]);
exports.optionalImage1 = (0, vue_1.ref)([]);
exports.optionalImage2 = (0, vue_1.ref)([]);
function resetInputData() {
    exports.orderNumber.value = null;
    exports.description.value = "";
    exports.phone.value = "";
    exports.email.value = "";
    exports.emailDisabled.value = false;
    exports.lastName.value = "";
    exports.firstName.value = "";
    exports.salutation.value = "";
    exports.isShoe.value = false;
    exports.articleImage.value = [];
    exports.defectImage.value = [];
    exports.soleImage.value = [];
    exports.receiptImage.value = [];
    exports.optionalImage1.value = [];
    exports.optionalImage2.value = [];
}
function isContactFormDirty() {
    var autofillFields = [exports.email.value, exports.lastName.value, exports.firstName.value, exports.salutation.value];
    var nonAutofillFields = [
        exports.orderNumber.value,
        exports.description.value,
        exports.phone.value,
        exports.articleImage.value,
        exports.defectImage.value,
        exports.soleImage.value,
        exports.receiptImage.value,
        exports.optionalImage1.value,
        exports.optionalImage2.value,
    ];
    var fields = use_autofill_1.wasAutofillExecuted ? nonAutofillFields : nonAutofillFields.concat(autofillFields);
    return fields.some(function (field) {
        if (Array.isArray(field))
            return field.length !== 0;
        return !!field;
    });
}
var returnEmailObject = function () { return __awaiter(void 0, void 0, Promise, function () {
    var data, imageRefs, images;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                data = {
                    language: ocm_object_1.language,
                    referTo: ocm_object_1.isClassicSite ? "Classic" : "Shop",
                    topic: ((_a = use_polly_1.currentCollection.value) === null || _a === void 0 ? void 0 : _a.name) || "",
                    orderNumber: exports.orderNumber.value,
                    phoneNumber: exports.phone.value,
                    email: exports.email.value,
                    surName: exports.lastName.value,
                    firstName: exports.firstName.value,
                    salutation: exports.salutation.value,
                    message: exports.description.value,
                    customerNumber: (0, exports.getCustomerNumber)(),
                };
                if (!use_restrictions_1.authRequired.value) return [3 /*break*/, 2];
                imageRefs = [exports.articleImage, exports.defectImage, exports.receiptImage, exports.optionalImage1, exports.optionalImage2];
                if (exports.isShoe.value)
                    imageRefs.push(exports.soleImage);
                return [4 /*yield*/, (0, use_imageUpload_1.getEmailImages)(imageRefs)];
            case 1:
                images = _b.sent();
                data.isShoe = exports.isShoe.value;
                data.images = images;
                _b.label = 2;
            case 2: return [2 /*return*/, data];
        }
    });
}); };
exports.returnEmailObject = returnEmailObject;
var getCustomerNumber = function () { var _a, _b, _c; return (_c = (_b = (_a = window.recommendationData) === null || _a === void 0 ? void 0 : _a.Recommendation) === null || _b === void 0 ? void 0 : _b.customerData) === null || _c === void 0 ? void 0 : _c.customerId; };
exports.getCustomerNumber = getCustomerNumber;
