"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
var SharedDialog_vue_1 = require("../SharedDialog/SharedDialog.vue");
var Modal_vue_1 = require("./Modal.vue");
var Vue3Modal_vue_1 = require("./Vue3Modal.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AccessibleModal',
    props: {
        closeActionButton: { type: Boolean },
        closeActionButtonText: {},
        fullMaxHeight: { type: Boolean },
        headline: {},
        narrow: { type: Boolean },
        noBodyPadding: { type: Boolean },
        noScrollbar: { type: Boolean },
        preventDismiss: { type: Boolean },
        wide: { type: Boolean },
        ifShowIsOpen: { type: Boolean, default: undefined },
        isVue2: { type: Boolean },
        zIndex: {}
    },
    emits: ["closing", "closed"],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var emit = __emit;
        var isDialogSupported = "showModal" in document.createElement("dialog");
        var dialogEl = (0, vue_3.ref)(null);
        var modalEl = (0, vue_3.ref)(null);
        var isOpen = (0, vue_3.ref)(false);
        var open = function () {
            var _a;
            isDialogSupported ? (_a = dialogEl.value) === null || _a === void 0 ? void 0 : _a.open() : (isOpen.value = true);
        };
        var close = function () {
            var _a;
            (_a = (isDialogSupported ? dialogEl.value : modalEl.value)) === null || _a === void 0 ? void 0 : _a.close();
        };
        var onClosing = function (triggeredBy) {
            emit("closing", triggeredBy);
        };
        var onClosed = function () {
            emit("closed");
            if (!isDialogSupported)
                isOpen.value = false;
        };
        __expose({ open: open, close: close });
        return function (_ctx, _cache) {
            return isDialogSupported
                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SharedDialog_vue_1.default, {
                    key: 0,
                    ref_key: "dialogEl",
                    ref: dialogEl,
                    "close-action-button": _ctx.closeActionButton,
                    "close-action-button-text": _ctx.closeActionButtonText,
                    "full-max-height": _ctx.fullMaxHeight,
                    headline: _ctx.headline,
                    narrow: _ctx.narrow,
                    "no-body-padding": _ctx.noBodyPadding,
                    "no-scrollbar": _ctx.noScrollbar,
                    "prevent-dismiss": _ctx.preventDismiss,
                    wide: _ctx.wide,
                    onClosing: onClosing,
                    onClosed: onClosed
                }, (0, vue_2.createSlots)({
                    footer: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.renderSlot)(_ctx.$slots, "footer")
                    ]; }),
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ]; }),
                    _: 2
                }, [
                    (_ctx.$slots.headerblock)
                        ? {
                            name: "headerblock",
                            fn: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.renderSlot)(_ctx.$slots, "headerblock")
                            ]; }),
                            key: "0"
                        }
                        : undefined
                ]), 1032, ["close-action-button", "close-action-button-text", "full-max-height", "headline", "narrow", "no-body-padding", "no-scrollbar", "prevent-dismiss", "wide"]))
                : (_ctx.isVue2 && isOpen.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(Modal_vue_1.default, {
                        key: 1,
                        ref_key: "modalEl",
                        ref: modalEl,
                        "close-action-button": _ctx.closeActionButton,
                        "close-action-button-text": _ctx.closeActionButtonText,
                        "full-max-height": _ctx.fullMaxHeight,
                        headline: _ctx.headline,
                        narrow: _ctx.narrow,
                        "no-body-padding": _ctx.noBodyPadding,
                        "no-scrollbar": _ctx.noScrollbar,
                        wide: _ctx.wide,
                        "z-index": _ctx.zIndex,
                        onClosing: onClosing,
                        onClosed: onClosed
                    }, (0, vue_2.createSlots)({
                        footer: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.renderSlot)(_ctx.$slots, "footer")
                        ]; }),
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.renderSlot)(_ctx.$slots, "default")
                        ]; }),
                        _: 2
                    }, [
                        (_ctx.$slots.headerblock)
                            ? {
                                name: "headerblock",
                                fn: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.renderSlot)(_ctx.$slots, "headerblock")
                                ]; }),
                                key: "0"
                            }
                            : undefined
                    ]), 1032, ["close-action-button", "close-action-button-text", "full-max-height", "headline", "narrow", "no-body-padding", "no-scrollbar", "wide", "z-index"]))
                    : (_ctx.ifShowIsOpen !== undefined || isOpen.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(Vue3Modal_vue_1.default, {
                            key: 2,
                            ref_key: "modalEl",
                            ref: modalEl,
                            "close-action-button": _ctx.closeActionButton,
                            "close-action-button-text": _ctx.closeActionButtonText,
                            "full-max-height": _ctx.fullMaxHeight,
                            headline: _ctx.headline,
                            "if-show-is-open": _ctx.ifShowIsOpen,
                            narrow: _ctx.narrow,
                            "no-body-padding": _ctx.noBodyPadding,
                            "no-scrollbar": _ctx.noScrollbar,
                            "prevent-dismiss": _ctx.preventDismiss,
                            wide: _ctx.wide,
                            onClosing: onClosing,
                            onClosed: onClosed
                        }, (0, vue_2.createSlots)({
                            footer: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.renderSlot)(_ctx.$slots, "footer")
                            ]; }),
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.renderSlot)(_ctx.$slots, "default")
                            ]; }),
                            _: 2
                        }, [
                            (_ctx.$slots.headerblock)
                                ? {
                                    name: "headerblock",
                                    fn: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.renderSlot)(_ctx.$slots, "headerblock")
                                    ]; }),
                                    key: "0"
                                }
                                : undefined
                        ]), 1032, ["close-action-button", "close-action-button-text", "full-max-height", "headline", "if-show-is-open", "narrow", "no-body-padding", "no-scrollbar", "prevent-dismiss", "wide"]))
                        : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
