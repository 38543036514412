"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 0,
    class: "mt-4 mb-6 text-xl font-semibold"
};
var vue_3 = require("vue");
var use_polly_1 = require("@/composables/use-polly");
require("@/composables/use-trackingVirtualPageViews");
var BackButton_vue_1 = require("@/components/BackButton.vue");
var TransitionSection_vue_1 = require("@/components/TransitionSection.vue");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var routeName_type_1 = require("@/types/routeName.type");
var InputStepsProgress_vue_1 = require("@/components/InputStepsProgress.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ContactLayout',
    setup: function (__props) {
        var contactRef = (0, vue_3.ref)();
        return function (_ctx, _cache) {
            var _component_router_view = (0, vue_2.resolveComponent)("router-view");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                id: "contact",
                ref_key: "contactRef",
                ref: contactRef,
                class: "tw relative m-auto max-w-2xl px-4 pb-20"
            }, [
                (0, vue_2.createVNode)(BackButton_vue_1.default),
                (0, vue_2.createVNode)(TransitionSection_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () {
                        var _a;
                        return [
                            (!(0, vue_2.unref)(use_pageNavigation_1.isCurrentPage)((0, vue_2.unref)(routeName_type_1.THANK_YOU_PAGE_NAME)) && !(0, vue_2.unref)(use_pageNavigation_1.isCurrentPage)((0, vue_2.unref)(routeName_type_1.LOGIN_INFO_PAGE_NAME)))
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("h2", _hoisted_1, (0, vue_2.toDisplayString)((0, vue_2.unref)(use_pageNavigation_1.isCurrentPage)((0, vue_2.unref)(routeName_type_1.SELECTION_PAGE_NAME)) ? _ctx.$t("headline") : (_a = (0, vue_2.unref)(use_polly_1.currentCollection)) === null || _a === void 0 ? void 0 : _a.name), 1))
                                : (0, vue_2.createCommentVNode)("", true)
                        ];
                    }),
                    _: 1
                }),
                (0, vue_2.createVNode)(InputStepsProgress_vue_1.default),
                (0, vue_2.createVNode)(_component_router_view, null, {
                    default: (0, vue_2.withCtx)(function (_a) {
                        var Component = _a.Component;
                        return [
                            (0, vue_2.createVNode)(TransitionSection_vue_1.default, null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.resolveDynamicComponent)(Component)))
                                ]; }),
                                _: 2
                            }, 1024)
                        ];
                    }),
                    _: 1
                })
            ], 512));
        };
    }
});
