"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FORM_ROUTE_NAMES = exports.THANK_YOU_PAGE_NAME = exports.PERSONAL_INFO_PAGE_NAME = exports.DESCRIPTION_PAGE_NAME = exports.IMAGE_UPLOAD_PAGE_NAME = exports.ORDER_PAGE_NAME = exports.LOGIN_INFO_PAGE_NAME = exports.HELP_PAGE_NAME = exports.SELECTION_PAGE_NAME = void 0;
exports.SELECTION_PAGE_NAME = "selectionPage";
exports.HELP_PAGE_NAME = "helpPage";
exports.LOGIN_INFO_PAGE_NAME = "loginInfoPage";
exports.ORDER_PAGE_NAME = "orderPage";
exports.IMAGE_UPLOAD_PAGE_NAME = "imageUploadPage";
exports.DESCRIPTION_PAGE_NAME = "descriptionPage";
exports.PERSONAL_INFO_PAGE_NAME = "personalInfoPage";
exports.THANK_YOU_PAGE_NAME = "thankYouPage";
exports.FORM_ROUTE_NAMES = [
    exports.ORDER_PAGE_NAME,
    exports.IMAGE_UPLOAD_PAGE_NAME,
    exports.DESCRIPTION_PAGE_NAME,
    exports.PERSONAL_INFO_PAGE_NAME,
];
