"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
var SvgIcon_vue_1 = require("./SvgIcon/SvgIcon.vue");
var SharedSpinner_vue_1 = require("./SharedSpinner.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SharedButton',
    props: {
        variant: { default: "primary" },
        dark: { type: Boolean },
        disabled: { type: Boolean },
        isLoading: { type: Boolean },
        to: {},
        orchestratorLink: { type: Boolean },
        type: { default: "button" },
        prependIcon: {},
        appendIcon: {},
        iconSize: { default: "5" },
        small: { type: Boolean }
    },
    setup: function (__props) {
        var props = __props;
        var isDisabled = (0, vue_3.computed)(function () { return props.disabled || (props.isLoading && !(0, vue_3.useAttrs)()["aria-disabled"]); });
        var hasSlot = (0, vue_3.computed)(function () { return !!(0, vue_3.useSlots)().default; });
        var isPrimary = (0, vue_3.computed)(function () { return props.variant === "primary"; });
        var isSecondary = (0, vue_3.computed)(function () { return props.variant === "secondary"; });
        var isProminent = (0, vue_3.computed)(function () { return props.variant === "prominent"; });
        var isPayPal = (0, vue_3.computed)(function () { return props.variant === "payPal"; });
        var isText = (0, vue_3.computed)(function () { return props.variant === "text"; });
        var isLink = (0, vue_3.computed)(function () { return props.to || (0, vue_3.useAttrs)().href; });
        var dynamicDataTestAttr = (0, vue_3.computed)(function () {
            return isLink.value ? "data-test-shared-button-anchor" : "data-test-shared-button";
        });
        return function (_ctx, _cache) {
            var _a;
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.resolveDynamicComponent)(isLink.value ? (_ctx.to ? 'RouterLink' : 'a') : 'button'), (0, vue_2.normalizeProps)((_a = {
                    class: ["shared-button inline-flex select-none items-center justify-center whitespace-nowrap rounded-full border align-top text-sm", {
                            'is-text border-transparent font-medium text-black-primary disabled:text-gray-50': isText.value,
                            'is-primary font-medium text-white disabled:text-gray-50': isPrimary.value && !_ctx.dark,
                            'is-primary-dark font-semibold text-black-primary focus-visible:outline-white disabled:text-gray-50': isPrimary.value && _ctx.dark,
                            'is-secondary font-semibold text-black-primary backdrop-blur-sm disabled:border-gray-30 disabled:text-gray-50': isSecondary.value && !_ctx.dark,
                            'is-secondary-dark font-medium backdrop-blur-sm focus-visible:outline-white': isSecondary.value && _ctx.dark,
                            'is-prominent font-medium text-white focus-visible:outline-red-primary disabled:text-gray-50': isProminent.value,
                            'is-paypal font-medium disabled:text-gray-50': isPayPal.value,
                            'is-disabled': _ctx.isLoading && _ctx.$attrs['aria-disabled'],
                            'px-8': hasSlot.value,
                            'h-11.5': !_ctx.small,
                            'w-11.5': !hasSlot.value && !_ctx.small,
                            'h-8 w-8': !hasSlot.value && _ctx.small,
                        }],
                    to: _ctx.to,
                    type: !isLink.value ? _ctx.type : undefined,
                    disabled: !isLink.value ? isDisabled.value : undefined,
                    onclick: isLink.value && _ctx.orchestratorLink ? 'window.ocmOrchestrator?.spaNavigateUrl(event)' : undefined,
                    style: { "-webkit-tap-highlight-color": "transparent" }
                },
                _a[dynamicDataTestAttr.value || ""] = true,
                _a)), {
                default: (0, vue_2.withCtx)(function () { return [
                    (_ctx.isLoading)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SharedSpinner_vue_1.default, {
                            key: 0,
                            dark: ""
                        }))
                        : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, [
                            (_ctx.prependIcon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SvgIcon_vue_1.default, {
                                    key: 0,
                                    class: (0, vue_2.normalizeClass)(["shrink-0", [_ctx.small ? 'h-3.5 w-3.5' : "h-".concat(_ctx.iconSize, " w-").concat(_ctx.iconSize), { 'mr-2': hasSlot.value || _ctx.appendIcon }]]),
                                    "icon-name": _ctx.prependIcon
                                }, null, 8, ["class", "icon-name"]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.renderSlot)(_ctx.$slots, "default"),
                            (_ctx.appendIcon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SvgIcon_vue_1.default, {
                                    key: 1,
                                    class: (0, vue_2.normalizeClass)(["shrink-0", [_ctx.small ? 'h-3.5 w-3.5' : "h-".concat(_ctx.iconSize, " w-").concat(_ctx.iconSize), { 'ml-2': hasSlot.value }]]),
                                    "icon-name": _ctx.appendIcon
                                }, null, 8, ["class", "icon-name"]))
                                : (0, vue_2.createCommentVNode)("", true)
                        ], 64))
                ]; }),
                _: 3
            }, 16, ["class", "to", "type", "disabled", "onclick"]));
        };
    }
});
