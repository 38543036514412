"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'InfoBox',
    props: {
        small: { type: Boolean },
        error: { type: Boolean }
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["grid grid-cols-[24px,1fr] rounded-lg", {
                        'bg-gray-20 p-4': !_ctx.small,
                        'text-gray-70': _ctx.small && !_ctx.error,
                        'text-error': _ctx.error,
                    }])
            }, [
                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                    class: "mr-1 text-lg",
                    "icon-name": "info"
                }),
                (0, vue_2.createElementVNode)("div", null, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ])
            ], 2));
        };
    }
});
