"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentFormFlow = exports.SONSTIGE = exports.currentCollection = void 0;
var vue_1 = require("vue");
exports.currentCollection = (0, vue_1.ref)();
/*
    POLLY MAPPING
        There are several more ids than listed here, every section (e.g ANMELDUNG_BENUTZERKONTO, NEWSLETTER, KARRIERE...)
        which is not defined will be handled like the default case
*/
// TestShop, Shop, TestShop AT, Shop AT, Testshop CH, Shop CH
var VERSAND_LIEFERUNG = [
    "aJu7zeEpzWkvAunZx",
    "KSDEdjJKktKxvRpen",
    "AGDKxKdBcbjfxmNqG",
    "tieDsj6rfRzfErtjw",
    "nWQLnhgcijBcRo83v",
    "Z84WogMuDiPjS8BeR",
];
var RETOURE_RUECKERSTATTUNG = [
    "2ZS4CgqmQZc4bzzPA",
    "3X9RGchGo3FSMsBhf",
    "kgQia4cnSJ39FcBtu",
    "SfyTi8p8MKtjn7mDd",
    "rGrAkr5vxNcxy8SHb",
    "4Jpt9EhaaGdaAHi9o",
];
var ZAHLUNG = [
    "n7GpHYTw3vYH8JspC",
    "tN5MmQy4hE6FtSSM2",
    "jHHjbDneytebgTH3L",
    "rBtXsbfbjMwqgBf9f",
    "783L6rPcMb42Krzt7",
    "a2XEFyuHHhRoDv6hp",
];
var GUTSCHEINE = [
    "GgAyPFsvhxT3dJcyX",
    "oShbYMkLAyCaKX4ti",
    "JMXpJ2cgHu2FXaLfP",
    "TsCjqhwSFw3v2n9EK",
    "sRznxLv2G33sEzAig",
    "cLNWukcZGhpZgrkb7",
];
var BESTELLUNG = [
    "3hjKSkfHK7XRcNahf",
    "HLnr9Wef99QxnxsxT",
    "MuzgaXeXM6XmJHCJM",
    "iZn9emvAYHkjLhauP",
    "qow6YW2DeyqnQqGxX",
    "yhEY5kiiXDjbioQni",
];
var REKLAMATION_EINES_ARTIKELS_SHOP = [
    "uTXnJaSBsHp6tMFCk",
    "W7yPstetW7t3LCgJx",
    "b82X7xLN9ptdPKHe3",
    "QxuGdREZADcHuPjfu",
    "TfT9pjRgom9MfxR4Y",
    "tABGCJvpNzuF7vHBT",
];
// Test
exports.SONSTIGE = "z8SvrCnTPe6BFRL9X"; /* special don't show faq */
var findCurrentCollectionId = function (allIds) {
    return allIds.find(function (id) { var _a; return ((_a = exports.currentCollection.value) === null || _a === void 0 ? void 0 : _a.id) === id; });
};
exports.currentFormFlow = (0, vue_1.computed)(function () {
    var _a;
    var currentCollectionId = ((_a = exports.currentCollection.value) === null || _a === void 0 ? void 0 : _a.id) || "";
    switch (currentCollectionId) {
        case findCurrentCollectionId(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], BESTELLUNG, true), VERSAND_LIEFERUNG, true), RETOURE_RUECKERSTATTUNG, true), ZAHLUNG, true), GUTSCHEINE, true)):
            return ["orderPage", "descriptionPage", "personalInfoPage"];
        case findCurrentCollectionId(__spreadArray([], REKLAMATION_EINES_ARTIKELS_SHOP, true)):
            return ["orderPage", "imageUploadPage", "descriptionPage", "personalInfoPage"];
        default:
            return ["descriptionPage", "personalInfoPage"];
    }
});
