"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var use_polly_1 = require("@/composables/use-polly");
var routeName_type_1 = require("@/types/routeName.type");
var vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BaseCard',
    props: {
        collection: {}
    },
    setup: function (__props) {
        var setCollectionState = function (collection) {
            use_polly_1.currentCollection.value = collection;
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_router_1.RouterLink), {
                id: _ctx.collection.id,
                to: { name: (0, vue_2.unref)(routeName_type_1.HELP_PAGE_NAME), params: { collectionId: _ctx.collection.id } },
                class: "grid place-content-center rounded-lg bg-white p-4 text-center text-sm shadow-contactCards hover:bg-gray-40 focus-visible:bg-gray-40",
                onClickOnce: _cache[0] || (_cache[0] = function ($event) { return (setCollectionState(_ctx.collection)); })
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.collection.name), 1)
                ]; }),
                _: 1
            }, 8, ["id", "to"]));
        };
    }
});
