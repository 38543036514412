"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mb-20 mt-10" };
var _hoisted_2 = { key: 1 };
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = { class: "mb-4 font-semibold" };
var _hoisted_5 = ["href"];
var vue_3 = require("vue");
var SharedExpandable_vue_1 = require("@SharedVueComponents/SharedExpandable/SharedExpandable.vue");
var SharedExpandableGroup_vue_1 = require("@SharedVueComponents/SharedExpandable/SharedExpandableGroup.vue");
var SharedButton_vue_1 = require("@SharedVueComponents/SharedButton.vue");
var BasicSkeleton_vue_1 = require("@SharedVueComponents/BasicSkeleton/BasicSkeleton.vue");
var HeadlineText_vue_1 = require("@/components/HeadlineText.vue");
var BaseFeedback_vue_1 = require("@/components/BaseFeedback.vue");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var use_polly_1 = require("@/composables/use-polly");
var vue_router_1 = require("vue-router");
var use_contactPhoneNumber_1 = require("@/composables/use-contactPhoneNumber");
var faq_query_1 = require("@/queries/faq.query");
var use_trackingInteractions_1 = require("@/composables/use-trackingInteractions");
var routeName_type_1 = require("@/types/routeName.type");
var use_redirect_1 = require("@/composables/use-redirect");
var publications_query_1 = require("@/queries/publications.query");
exports.default = (0, vue_1.defineComponent)({
    __name: 'HelpPage',
    setup: function (__props) {
        var _this = this;
        var avoidFlickeringTimeout = (0, vue_3.ref)(false);
        var amountSlicedItems = (0, vue_3.ref)(5);
        var router = (0, vue_router_1.useRouter)();
        var faqIdFromParam = (0, vue_3.ref)();
        (0, vue_3.onBeforeMount)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, use_redirect_1.redirectLocalStorage)()];
                    case 1:
                        _a.sent();
                        setFAQIdFromParams();
                        setCollectionIdFromParams();
                        setTimeout(function () {
                            avoidFlickeringTimeout.value = true;
                        }, 300);
                        if (!(use_polly_1.currentCollection.value && (faq_query_1.data.value === undefined || !faq_query_1.data.value[use_polly_1.currentCollection.value.id]))) return [3 /*break*/, 3];
                        return [4 /*yield*/, (0, faq_query_1.getFaqs)(use_polly_1.currentCollection.value.id)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        redirectNoFaqs();
                        avoidFlickeringTimeout.value = false;
                        return [2 /*return*/];
                }
            });
        }); });
        (0, vue_3.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (faqIdFromParam.value) {
                    showAllItems();
                }
                return [2 /*return*/];
            });
        }); });
        var showAllItems = function () {
            amountSlicedItems.value = 0;
        };
        var setFAQIdFromParams = function () {
            var url = new URL(window.location.href);
            var params = new URLSearchParams(url.search);
            faqIdFromParam.value = params.get("faq");
        };
        var setCollectionIdFromParams = function () {
            var collectionId = router.currentRoute.value.params.collectionId;
            if (typeof collectionId === "string")
                use_polly_1.currentCollection.value = (0, publications_query_1.getCollectionFromId)(collectionId);
        };
        var redirectNoFaqs = function () {
            var _a, _b, _c;
            var hasValidData = use_polly_1.currentCollection.value && faq_query_1.data.value && ((_b = (_a = faq_query_1.data.value[use_polly_1.currentCollection.value.id]) === null || _a === void 0 ? void 0 : _a.collection) === null || _b === void 0 ? void 0 : _b.articles.length);
            if (!hasValidData) {
                var backButtonNavigation = router.currentRoute.value.query.backNavigationNavigation;
                if (backButtonNavigation) {
                    router.push({ name: routeName_type_1.SELECTION_PAGE_NAME });
                }
                else {
                    (0, use_pageNavigation_1.startContactForm)((_c = use_polly_1.currentCollection.value) === null || _c === void 0 ? void 0 : _c.id, true);
                }
            }
        };
        var shownItems = (0, vue_3.computed)(function () {
            var _a, _b, _c, _d;
            if (!faq_query_1.data.value || !use_polly_1.currentCollection.value)
                return [];
            if (amountSlicedItems.value > 0)
                return ((_b = (_a = faq_query_1.data.value[use_polly_1.currentCollection.value.id]) === null || _a === void 0 ? void 0 : _a.collection) === null || _b === void 0 ? void 0 : _b.articles.slice(0, amountSlicedItems.value)) || [];
            return ((_d = (_c = faq_query_1.data.value[use_polly_1.currentCollection.value.id]) === null || _c === void 0 ? void 0 : _c.collection) === null || _d === void 0 ? void 0 : _d.articles) || [];
        });
        var showButton = (0, vue_3.computed)(function () {
            var _a, _b, _c, _d;
            return faq_query_1.data.value &&
                use_polly_1.currentCollection.value &&
                amountSlicedItems.value > 0 &&
                amountSlicedItems.value !== ((_b = (_a = faq_query_1.data.value[use_polly_1.currentCollection.value.id]) === null || _a === void 0 ? void 0 : _a.collection) === null || _b === void 0 ? void 0 : _b.articles.length) &&
                (((_d = (_c = faq_query_1.data.value[use_polly_1.currentCollection.value.id]) === null || _c === void 0 ? void 0 : _c.collection) === null || _d === void 0 ? void 0 : _d.articles.length) || 0) > 5;
        });
        var showMore = function () {
            var _a;
            if (!faq_query_1.data.value || !use_polly_1.currentCollection.value)
                return;
            amountSlicedItems.value = ((_a = faq_query_1.data.value[use_polly_1.currentCollection.value.id].collection) === null || _a === void 0 ? void 0 : _a.articles.length) || 999;
            (0, use_trackingInteractions_1.trackShowMoreFAQsClicked)();
        };
        return function (_ctx, _cache) {
            var _component_i18n_t = (0, vue_2.resolveComponent)("i18n-t");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(HeadlineText_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("helpPage.headline")), 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                    ((0, vue_2.unref)(faq_query_1.fetching) && avoidFlickeringTimeout.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, (0, vue_2.renderList)(5, function (n) {
                            return (0, vue_2.createVNode)(BasicSkeleton_vue_1.default, {
                                key: "skeleton-".concat(n),
                                class: "mb-1 h-13"
                            });
                        }), 64))
                        : ((0, vue_2.unref)(faq_query_1.error))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.$t("generalError")), 1))
                            : ((0, vue_2.unref)(faq_query_1.data))
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 2 }, [
                                    (0, vue_2.createVNode)(SharedExpandableGroup_vue_1.default, null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(shownItems.value, function (article, i) {
                                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SharedExpandable_vue_1.default, {
                                                    id: article.id,
                                                    key: i,
                                                    title: article.name,
                                                    "is-expanded": article.id === faqIdFromParam.value,
                                                    onHeaderClicked: function ($event) { return ((0, vue_2.unref)(use_trackingInteractions_1.trackQuestionClicked)(article.name)); }
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(article.schema_elements, function (element, j) {
                                                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("p", {
                                                                key: j,
                                                                class: "mb-4",
                                                                innerHTML: element.content
                                                            }, null, 8, _hoisted_3));
                                                        }), 128)),
                                                        (0, vue_2.createVNode)(BaseFeedback_vue_1.default, {
                                                            "article-id": article.id,
                                                            "article-name": article.name
                                                        }, null, 8, ["article-id", "article-name"])
                                                    ]; }),
                                                    _: 2
                                                }, 1032, ["id", "title", "is-expanded", "onHeaderClicked"]));
                                            }), 128))
                                        ]; }),
                                        _: 1
                                    }),
                                    (showButton.value)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                                            key: 0,
                                            type: "button",
                                            class: "ocm-body2 p-4 underline",
                                            onClick: showMore
                                        }, (0, vue_2.toDisplayString)(_ctx.$t("helpPage.showMore")), 1))
                                        : (0, vue_2.createCommentVNode)("", true)
                                ], 64))
                                : (0, vue_2.createCommentVNode)("", true)
                ]),
                (0, vue_2.createElementVNode)("p", _hoisted_4, (0, vue_2.toDisplayString)(_ctx.$t("helpPage.nothingSuitable")), 1),
                (0, vue_2.createVNode)(SharedButton_vue_1.default, {
                    onClick: _cache[0] || (_cache[0] = function ($event) {
                        var _a;
                        (0, vue_2.unref)(use_trackingInteractions_1.trackContinueToFormClicked)();
                        (0, vue_2.unref)(use_pageNavigation_1.startContactForm)((_a = (0, vue_2.unref)(use_polly_1.currentCollection)) === null || _a === void 0 ? void 0 : _a.id);
                    })
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("helpPage.cta")), 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(_component_i18n_t, {
                    tag: "p",
                    class: "mt-4",
                    keypath: "helpPage.ctaAlternative"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createElementVNode)("a", {
                            type: "tel",
                            href: "tel:".concat((0, vue_2.unref)(use_contactPhoneNumber_1.contactPhoneNumber)()),
                            class: "font-medium"
                        }, [
                            _cache[1] || (_cache[1] = (0, vue_2.createElementVNode)("br", null, null, -1)),
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(use_contactPhoneNumber_1.contactPhoneNumber)()), 1)
                        ], 8, _hoisted_5)
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
