"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["aria-hidden", "aria-labelledby"];
var _hoisted_2 = ["id"];
var _hoisted_3 = ["xlink:href", "href"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'SvgIcon',
    props: {
        iconName: {},
        title: {}
    },
    setup: function (__props) {
        var titleId = (Math.random() + 1).toString(36).substring(2);
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("svg", {
                class: (0, vue_2.normalizeClass)(["svg-icon", "icon-".concat(_ctx.iconName)]),
                "aria-hidden": !_ctx.title ? 'true' : undefined,
                "aria-labelledby": _ctx.title ? (0, vue_2.unref)(titleId) : undefined
            }, [
                (_ctx.title)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("title", {
                        key: 0,
                        id: (0, vue_2.unref)(titleId)
                    }, (0, vue_2.toDisplayString)(_ctx.title), 9, _hoisted_2))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("use", {
                    "xlink:href": "#icon-".concat(_ctx.iconName),
                    href: "#icon-".concat(_ctx.iconName)
                }, null, 8, _hoisted_3)
            ], 10, _hoisted_1));
        };
    }
});
